import { WhereaboutsModal } from "core/components/shared/WhereaboutsModal";
import { EditPencil, PlusCircle } from "iconoir-react";
import { Flex } from "styled-system/jsx";
import { Select, Text, toastFactory } from "@ttc3k/trekker";
import { EnumPriceTierType } from "gql/generated";
import { FormProvider, useWatch } from "react-hook-form";
import { ControlledInput } from "apps/custom/ttc/booker/shared";
import { useState } from "react";
import { UpdateAccomPriceFormValues } from "./useUpdateAccomPriceForm";
import {
  AddEditGuestDataFormValues,
  useAddEditGuestDataForm,
} from "./useAddEditGuestDataForm";

type AddGuestTypeModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSave: (
    b: UpdateAccomPriceFormValues["guests"][0],
    isDeleting: boolean,
  ) => void;
  guest: UpdateAccomPriceFormValues["guests"][0];
  guestMaximum: string;
};

const guestTypeOptions = [
  { value: EnumPriceTierType.Adult, label: "Adult" },
  { value: EnumPriceTierType.Child, label: "Child" },
  { value: EnumPriceTierType.Infant, label: "Infant" },
  { value: EnumPriceTierType.Senior, label: "Senior" },
  { value: EnumPriceTierType.General, label: "General" },
  { value: EnumPriceTierType.Pet, label: "Pet" },
];

export const AddEditGuestTypeModal = ({
  isOpen,
  onClose,
  guest,
  onSave,
  guestMaximum,
}: AddGuestTypeModalProps) => {
  const [errorMessage, setErrorMessage] = useState("");
  const { form, onSubmit, defaultValues } = useAddEditGuestDataForm({
    defaultValues: guest,
    onSuccess: v => handleSuccess(v),
    guestMaximum,
    onError: m => setErrorMessage(m),
  });

  const isEditing =
    !!defaultValues &&
    !!defaultValues.label &&
    defaultValues?.label?.length > 0;

  function handleSuccess(v: AddEditGuestDataFormValues) {
    onSave(v, false);
    setErrorMessage("");
    toastFactory.create({
      title: isEditing ? "Guest updated" : "Guest created",
      description: "Don't forget to save your changes up top!",
    });
  }

  const guestType = useWatch({ control: form.control, name: "type" });

  const handleCancelClick = () => {
    if (isEditing) {
      onSave(guest, true);
    } else {
      onClose();
    }
  };

  return (
    <WhereaboutsModal
      isOpen={isOpen}
      onClose={onClose}
      HeaderIcon={isEditing ? EditPencil : PlusCircle}
      title={isEditing ? "Edit guest type" : "Add a guest type"}
      cancelButtonProps={{
        onClick: handleCancelClick,
        visual: isEditing ? "destructive" : "outline",
        type: "button",
      }}
      cancelButtonCopy={isEditing ? "Delete" : "Cancel"}
      saveButtonCopy={isEditing ? "Update" : "Create"}
      saveButtonProps={{
        onClick: onSubmit,
      }}
    >
      <FormProvider {...form}>
        <Flex flexDir={"column"} gap={"150"} alignItems={"flex-start"}>
          <Select.Root
            items={guestTypeOptions}
            value={[guestType]}
            onValueChange={({ value }: { value: string[] }) =>
              form.setValue("type", value[0])
            }
            label={"Guest type"}
            width={"full"}
            css={{
              "& [data-scope='select'][data-part='trigger']": {
                bg: "white",
              },
            }}
          >
            {guestTypeOptions?.map(item => (
              <Select.Item key={item.value} item={item} />
            ))}
          </Select.Root>
          <ControlledInput
            name="label"
            label={"Custom label"}
            hint={"Use this label to specify age range, etc."}
          />
          <Flex gap={"250"}>
            <ControlledInput
              label={"Minimum guests for this tier"}
              name={"minimum"}
              type={"number"}
              max={guestMaximum}
            />
            <ControlledInput
              label={"Maximum guests for this tier"}
              name={"maximum"}
              type={"number"}
              max={guestMaximum}
            />
          </Flex>
          {errorMessage.length > 0 && (
            <Text visual={"smallSemiBold"} color={"text.error.mid"}>
              {errorMessage}
            </Text>
          )}
        </Flex>
      </FormProvider>
    </WhereaboutsModal>
  );
};
