import { Flex } from "styled-system/jsx";
import { Heading, Text } from "@ttc3k/trekker";
import { ConfigSpace, crumbs as crumbsType } from "./PreferredLayout";
import { SpacesBreadcrumbs } from "./Breadcrumbs/SpacesBreadcrumbs";
import ActionButton, { ActionButtonProps } from "./ActionButton";

type SpacesHeaderProps = {
  title?: string;
  description?: string | JSX.Element;
  actionButton: ActionButtonProps | null;
  crumbs: crumbsType;
  spaces: ConfigSpace[];
  actionSection?: JSX.Element;
};

export const SpacesHeaders = ({
  actionButton,
  crumbs,
  description,
  title,
  spaces,
  actionSection,
}: SpacesHeaderProps) => {
  return (
    <Flex
      bg={"white"}
      px={{ base: "400", lg: "1000" }}
      py={{ base: "500", lg: "600" }}
      gap={"600"}
      flexDir={{ base: "column", xl: "row" }}
      justifyContent={{ xl: "space-between" }}
      alignItems={{ xl: "center" }}
    >
      <Flex
        flexDir={"column"}
        gap={"150"}
        maxWidth={{ base: "full", lg: "600px" }}
      >
        <SpacesBreadcrumbs crumbs={crumbs} spaces={spaces} />
        <Heading size={"h3"}>{title}</Heading>
        <Text fontSize={"sm"} lineHeight={"sm"} color={"text.mid"} lineClamp={3}>
          {description}
        </Text>
      </Flex>
      {actionButton ? <ActionButton {...actionButton} /> : actionSection}
    </Flex>
  );
};
