import { useLocation, useNavigate } from "react-router-dom";
import {
  LegacyLoadingSpinner,
  LegacyTabNav,
  LegacyToast,
  StatusVariants,
} from "@ttc3k/ttc-design-system";
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Search, XmarkCircleSolid } from "iconoir-react";
import debounce from "lodash/debounce";
import { TextField } from "core/components/shared/TextField";
import { useGetCoursesByQuery } from "gql/generated.ts";
import { Branch } from "core/components/shared/Branch";

import CreateUserProfile from "../../../../../core/components/shared/UserProfile/CreateUserProfile.tsx";
import AllEntities from "./AllEntities";
import { gql, useQuery } from "@apollo/client";
import { util } from "prettier";
import skip = util.skip;

const GET_PERSON = gql`
  query PersonMany($limit: Int, $filter: FilterFindManyPersonInput) {
    personMany(limit: $limit, filter: $filter) {
      firstName
      lastName
      accountStatus
      email
      auth0Id
      tenantsOf {
        tenantId
        role
        _id
      }
      learner {
        courses {
          courseId
          courseName
          startDate
          lastAccessDate
          completedDate
          status
          suspendData
        }
        stats {
          startDate
          totalCoursesCompleted
          totalCoursesStarted
        }
      }
      _id
      createdDateISO
      updatedDateISO
    }
  }
`;

const GET_TEAM = gql`
  query GetTeamInfo($name: String) {
    getTeamInfo(name: $name) {
      dmo
    }
  }
`;

export function Branches({ defaultTenant }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuth0();
  const previousLocation = location.state?.previousLocation;
  const [search, setSearch] = useState("");
  const [filterStatus, setStatusFilter] = useState(
    location?.state?.filterStatus ?? "All",
  );
  const [personalProfileAlert, setPersonalProfileAlert] = useState(null);

  const {
    data: tenantData,
    error: tenantError,
    loading: tenantLoading,
  } = useQuery(GET_TEAM, {
    variables: {
      name: user?.whereaboutsTenants[0],
    },
  });

  const { data, loading, error, fetchMore, refetch } = useGetCoursesByQuery({
    variables: {
      input: {
        sort: "createdDateISO",
        filterBy: {
          tenantsOf: tenantData?.getTeamInfo.dmo,
          name: "",
        },
      },
    },
    skip: !tenantData?.getTeamInfo.dmo,
  });

  const {
    data: peopleData,
    error: peopleError,
    loading: peopleLoading,
    refetch: refetchPeople,
    networkStatus,
  } = useQuery(GET_PERSON, {
    variables: {
      limit: 1,
      filter: { auth0Id: user?.sub },
    },
    fetchPolicy: "no-cache",
  });

  const courses = useMemo(() => {
    const coursesByStatus = {
      all: data?.coursesBy,
      inProgress: [],
      complete: [],
    };
    data?.coursesBy?.forEach(item => {
      const itemFound = peopleData?.personMany[0]?.learner?.courses?.find(
        c => c?.courseId == item?._id,
      );
      if (!itemFound) return;
      else if (itemFound?.status == "IN_PROGRESS")
        return coursesByStatus.inProgress.push(item);
      else if (itemFound?.status == "COMPLETE")
        return coursesByStatus.complete.push(item);
    });
    return coursesByStatus;
  }, [data, peopleData]);

  const debouncedSearch = useCallback(
    debounce((text: string) => {
      refetch({
        input: {
          sort: "createdDateISO",
          limit: 1000,
          filterBy: {
            tenantsOf: tenantData?.getTeamInfo.dmo,
            name: text,
          },
        },
      });
    }, 400),
    [tenantData?.getTeamInfo.dmo],
  );

  const searchHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    debouncedSearch(e.target.value);
  };

  const ClearSearchHandler = () => {
    setSearch("");
    refetch({
      input: {
        sort: "createdDateISO",
        limit: 1000,
        filterBy: {
          tenantsOf: tenantData?.getTeamInfo.dmo,
          name: "",
        },
      },
    });
  };

  const tabHandler = (tab: string) => {
    setStatusFilter(tab);
  };

  useEffect(() => {
    if (peopleError || !peopleData || peopleData?.personMany?.length === 0) {
      setPersonalProfileAlert({
        message:
          "Oops, you don't have a personal profile in Whereabouts. We can't save learner progress until you create one",
        color: "Error",
      });
    }
    if (peopleData && peopleData.personMany?.length > 0) {
      setPersonalProfileAlert({
        message: `Welcome, ${peopleData?.personMany[0].firstName}! Your learner progress will be saved.`,
        color: "Success",
      });
    }
  }, [peopleError, peopleData]);

  // if (networkStatus === NetworkStatus.refetch) return "Refetching!";
  if (loading || peopleLoading || tenantLoading)
    return <LegacyLoadingSpinner />;
  if (error) return <p>Error : {error.message}</p>;

  const handleCreateUserClose = id => {
    //Refetch?
    refetchPeople({ limit: 1, filter: { auth0Id: id } });
  };

  const allCount = courses.all?.length ?? 0;
  const inProgressCount = courses.inProgress?.length ?? 0;
  const completeCount = courses.complete?.length ?? 0;

  const tabs = [
    {
      id: "1",
      count: allCount == 0 ? undefined : allCount,
      name: "All",
      onClick: () => tabHandler("All"),
    },
    {
      id: "2",
      count: inProgressCount == 0 ? undefined : inProgressCount,
      name: "In Progress",
      onClick: () => tabHandler("in-progress"),
    },
    {
      id: "3",
      count: completeCount == 0 ? undefined : completeCount,
      name: "Complete",
      onClick: () => tabHandler("complete"),
    },
  ];

  const getActiveTabFromString = (tabString: string) => {
    return tabs.find(t => t.name === tabString);
  };

  const activeTab = location?.state?.filterStatus
    ? getActiveTabFromString(location?.state?.filterStatus)
    : tabs[0];

  return (
    <Branch
      sideBarPosition="left"
      sideBarComponent={
        <div className="tw-flex tw-flex-col tw-gap-y-10 tw-mb-8">
          <LegacyTabNav
            title="Views"
            tabs={tabs}
            activeTab={activeTab}
            vertical
          />
        </div>
      }
    >
      {personalProfileAlert && (
        <LegacyToast variant={StatusVariants[personalProfileAlert.color]}>
          <div style={{ marginRight: "10px" }}>
            {personalProfileAlert.message}
          </div>
          {personalProfileAlert.color == "Error" && (
            <CreateUserProfile user={user} onComplete={handleCreateUserClose} />
          )}
        </LegacyToast>
      )}
      {filterStatus === "All" && (
        <TextField
          startIcon={<Search color="#71717A" />}
          endIcon={
            search && (
              <XmarkCircleSolid
                className="tw-cursor-pointer active:tw-scale-95 hover:tw-scale-105"
                color="#71717A"
                onClick={ClearSearchHandler}
              />
            )
          }
          placeholder="Search"
          value={search}
          onChange={searchHandler}
          autoFocus
        />
      )}
      {data?.coursesBy && filterStatus === "All" && (
        <AllEntities
          refetchPeople={() => refetchPeople({ limit: 1, auth0Id: user?.sub })}
          user={user}
          profileData={peopleData?.personMany[0]}
          data={courses.all}
          fetchMore={fetchMore}
          previousLocation={previousLocation}
          isAdmin={user?.whereaboutsOnboarding.isAdmin}
          filterStatus={filterStatus}
        />
      )}
      {data?.coursesBy && filterStatus === "in-progress" && (
        <AllEntities
          refetchPeople={() => refetchPeople({ limit: 1, auth0Id: user?.sub })}
          user={user}
          profileData={peopleData?.personMany[0]}
          data={courses.inProgress}
          fetchMore={fetchMore}
          previousLocation={previousLocation}
          isAdmin={user?.whereaboutsOnboarding.isAdmin}
          filterStatus={filterStatus}
        />
      )}
      {data?.coursesBy && filterStatus === "complete" && (
        <AllEntities
          refetchPeople={() => refetchPeople({ limit: 1, auth0Id: user?.sub })}
          profileData={peopleData?.personMany[0]}
          data={courses.complete}
          fetchMore={fetchMore}
          previousLocation={previousLocation}
          isAdmin={user?.whereaboutsOnboarding.isAdmin}
          filterStatus={filterStatus}
        />
      )}
    </Branch>
  );
}
