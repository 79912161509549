import { useBookerContext } from "booker/contexts";
import { HeroImage } from "booker/features/HeroImage";
import { ModalContentLayout, StripeElementsWrapper } from "booker/shared";
import { OperatorDetails } from "../OperatorDetails";
import { ProductDetails } from "../ProductDetails";
import { Steps } from "../Steps";
import { Checkout } from "../Checkout";

export const SingleProductModalContent = () => {
  const { product, operator, error, loading } = useBookerContext();

  if (error && !loading) {
    throw new Error("Error loading product and/or operator data");
  }

  return (
    <>
      <HeroImage images={product?.images} />
      {operator?.bookerOptions?.payment?.connectedAccountId && (
        <StripeElementsWrapper
          connectedAccountId={
            operator?.bookerOptions?.payment?.connectedAccountId ?? ""
          }
        >
          <ModalContentLayout
            imagesExist={!!product?.images && product?.images.length > 0}
          >
            <OperatorDetails />
            <ProductDetails />
            <Steps />
            <Checkout />
          </ModalContentLayout>
        </StripeElementsWrapper>
      )}
    </>
  );
};
