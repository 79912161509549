import { BookerPosition } from "apps/custom/ttc/booker/App";
import { useBookerByMainProductIdQuery } from "gql/generated";
import { useMemo, useState } from "react";

type EmbedStyle = {
  text: string;
  bgColor: string;
  color: string;
  borderRadius: string;
  bookerPosition: BookerPosition;
};

type EmbedDetailsReturn = {
  style: EmbedStyle;
  onUpdateEmbedStyle: (value: string, key: keyof EmbedStyle) => void;
  onResetEmbedStyle: () => void;
  embedCodeString: string;
};

export const useEmbedDetails = (productID: string): EmbedDetailsReturn => {
  const { data } = useBookerByMainProductIdQuery({
    variables: { productId: productID },
  });
  const defaultStyle: EmbedStyle = {
    text: "Book now",
    bgColor: "#27272A",
    color: "white",
    borderRadius: "8",
    bookerPosition: "right",
  };
  const [style, setStyle] = useState<EmbedStyle>(defaultStyle);

  const handleUpdateEmbedStyle = (value: string, key: keyof EmbedStyle) => {
    setStyle(prev => ({ ...prev, [key]: value }));
  };

  const handleResetEmbedStyle = () => {
    setStyle(defaultStyle);
  };

  const embedCodeString = useMemo(() => {
    const styleArray = [
      style.borderRadius !== "8" && style.borderRadius.length > 0
        ? `border-radius:${style.borderRadius}px`
        : null,
      style.color.length > 0 ? `color: ${style.color}` : null,
    ].filter(Boolean);

    return `
      <div class="ttc-booker-container"
        ${styleArray.length > 0 ? `style="${styleArray.join("; ")}"` : ""}
        data-booker-id="${data?.bookerByMainProductId?._id ?? ""}"
        ${style.bgColor ? `data-button-bg-color="${style.bgColor}"` : ""}
        data-button-copy="${style.text}"
        ${style.bookerPosition === "left" ? `data-booker-position="left"` : ""}
      ></div>
      <script type="module" src="https://booker.whereabouts.tech"></script>
    `;
  }, [
    data?.bookerByMainProductId?._id,
    style.bgColor,
    style.bookerPosition,
    style.borderRadius,
    style.color,
    style.text,
  ]);

  return {
    style,
    onUpdateEmbedStyle: handleUpdateEmbedStyle,
    onResetEmbedStyle: handleResetEmbedStyle,
    embedCodeString,
  };
};
