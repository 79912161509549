import { useEffect, useState } from "react";
import { AnimatePresence } from "framer-motion";
import { useParams } from "react-router-dom";
import { EnumProductType, useProductByIdQuery } from "gql/generated";
import { isStringProductType } from "../utils";
import { AccommodationProduct } from "./AccommodationProduct";
import { FeeProduct } from "./FeeProduct";

export default function ProductBranches() {
  const { id } = useParams();
  const isIdProductType = isStringProductType(id ?? "");
  const [branch, setBranch] = useState(isIdProductType ? id : "FEE");
  const { data, loading, error } = useProductByIdQuery({
    variables: { id: id ?? "" },
  });

  useEffect(() => {
    if (!isIdProductType && id && data && data.productById?.type) {
      setBranch(data.productById.type);
    }
  }, [data, id, isIdProductType]);

  return (
    <AnimatePresence>
      {branch === (EnumProductType.Fee as string) && (
        <FeeProduct
          product={data?.productById}
          error={error}
          loading={loading}
        />
      )}
      {branch === (EnumProductType.Accommodation as string) && (
        <AccommodationProduct
          product={data?.productById}
          error={error}
          loading={loading}
        />
      )}
    </AnimatePresence>
  );
}
