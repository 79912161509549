import { Portal } from "@headlessui/react";
import { Button, SectionHeader, Text } from "@ttc3k/trekker";
import { BookerModal } from "apps/custom/ttc/booker/App";
import { Eye } from "iconoir-react";
import { useState } from "react";
import { Flex } from "styled-system/jsx";

type BookerPreviewBoxProps = {
  bookerID?: string;
};

export const BookerPreviewBox = ({ bookerID }: BookerPreviewBoxProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <Flex
      minWidth={{ base: "full", lg: "400px" }}
      width={{ base: "full", lg: "400px" }}
      boxSizing={"border-box"}
      position={{ base: "static", lg: "sticky" }}
      top={"3.75rem"}
      flexDir={"column"}
      h={"full"}
      p={"400"}
      bg={"bg.wa.light"}
      borderWidth={"1px"}
      borderColor={"border.light"}
      borderRadius={"100"}
      gap={"300"}
    >
      <Flex flexDir={"column"} gap={"100"}>
        <SectionHeader text={"Booker Preview"} Icon={Eye} />
        <Text color={"text.mid"}>
          As you create your product, we’ll put together a live preview of what
          your finished Booker for it will look like. After you save your
          changes, click the launcher button below to see how it’s all coming
          together.
        </Text>
      </Flex>
      <Button
        visual={"success"}
        size={"lg"}
        disabled={!bookerID}
        onClick={() => setIsModalOpen(true)}
      >
        Book Now
      </Button>
      <Portal>
        <BookerModal
          bookerID={bookerID ?? ""}
          onClose={() => setIsModalOpen(false)}
          open={isModalOpen}
          bookerPosition={"right"}
        />
      </Portal>
    </Flex>
  );
};
