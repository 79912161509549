import { useFormHandler } from "core/hooks/useFormHandler";
import { removeTypename } from "core/utils/removeTypename";
import {
  EnumRoomType,
  ProductByIdDocument,
  useProductByIdQuery,
  useProductUpdateMutation,
} from "gql/generated";
import _ from "lodash";
import { useCallback, useMemo } from "react";
import { z } from "zod";

const updateAccomFormSchema = z.object({
  bedrooms: z.array(
    z.object({ _id: z.string(), amenities: z.array(z.string()) }),
  ),
  bathrooms: z
    .string()
    .transform(val => parseFloat(val))
    .refine(val => !isNaN(val) && val >= 0, {
      message: "Please enter a positive number",
    })
    .transform(val => val.toString()),
  amenities: z.array(z.string()),
});

export type UpdateAccomFeaturesFormValues = z.infer<
  typeof updateAccomFormSchema
>;

interface UpdateAccomFormOptions {
  onSuccess?: (v: string) => void;
  onError?: (message: string) => void;
  productID: string;
}

export const useUpdateAccomFeaturesForm = ({
  onSuccess,
  onError,
  productID,
}: UpdateAccomFormOptions) => {
  const { data: productData } = useProductByIdQuery({
    variables: { id: productID },
  });

  const cleanedData = removeTypename(productData);

  const defaultValues: UpdateAccomFeaturesFormValues = useMemo(
    () => ({
      bedrooms: productData?.productById?.accommodationMetadata?.rooms
        ? productData.productById.accommodationMetadata.rooms
            .filter(r => r?.type === EnumRoomType.Bed)
            .map(r => ({ amenities: _.compact(r?.amenities), _id: r?._id }))
        : [],
      bathrooms: productData?.productById?.accommodationMetadata?.rooms
        ? productData.productById.accommodationMetadata.rooms
            .filter(r => r?.type === EnumRoomType.Bath)
            .length.toString()
        : "0",
      amenities:
        productData?.productById?.accommodationMetadata?.amenities.map(
          a => a._id,
        ) ?? [],
    }),
    [
      productData?.productById?.accommodationMetadata?.amenities,
      productData?.productById?.accommodationMetadata?.rooms,
    ],
  );

  const [updateProduct] = useProductUpdateMutation();
  return useFormHandler<UpdateAccomFeaturesFormValues>(
    useCallback(
      async data => {
        const payload = { ...data };
        const { bedrooms, bathrooms, amenities } = payload;

        const bathroomsArray = new Array(bathrooms ? Number(bathrooms) : 0)
          .fill("")
          .map(() => ({
            type: EnumRoomType.Bath,
            amenities: [],
          }));
        const bedroomsArray = _.compact(
          bedrooms?.map(v => ({
            type: EnumRoomType.Bed,
            amenities: _.compact(v?.amenities),
          })),
        );

        const amenitiesArray = _.compact(amenities) ?? [];

        const formErrorMessage =
          "Could not update this product, please try again later";

        if (productID && productData && productData?.productById) {
          updateProduct({
            variables: {
              id: productID,
              record: {
                accommodationMetadata: {
                  ...cleanedData?.productById?.accommodationMetadata,
                  amenities: amenitiesArray,
                  rooms: bedroomsArray.concat(bathroomsArray),
                },
              },
            },
            refetchQueries: [ProductByIdDocument],
          })
            .then(
              () => onSuccess?.(productData.productById?.name.en ?? "Product"),
            )
            .catch(() => onError?.(formErrorMessage));
        } else {
          onError?.(formErrorMessage);
        }
      },
      [
        cleanedData?.productById?.accommodationMetadata,
        onError,
        onSuccess,
        productData,
        productID,
        updateProduct,
      ],
    ),
    defaultValues,
    { schema: updateAccomFormSchema },
  );
};
