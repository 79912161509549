import { Button, ColorPicker, Icon, Input, Select, Text } from "@ttc3k/trekker";
import { WhereaboutsModal } from "core/components/shared/WhereaboutsModal";
import { Code, Eye, Palette } from "iconoir-react";
import { Box, Flex } from "styled-system/jsx";
import { useState } from "react";
import { useEmbedDetails } from "./useEmbedDetails";

type EmbedDetailsModalProps = {
  isOpen: boolean;
  onClose: () => void;
  productID: string;
};

const bookerPositionOptions = [
  { label: "Right", value: "right" },
  { label: "Left", value: "left" },
];

export const EmbedDetailsModal = ({
  isOpen,
  onClose,
  productID,
}: EmbedDetailsModalProps) => {
  const [copyButtonText, setCopyButtonText] = useState("Copy embed code");
  const { embedCodeString, onUpdateEmbedStyle, onResetEmbedStyle, style } =
    useEmbedDetails(productID);

  const handleClose = () => {
    onResetEmbedStyle();
    onClose();
  };

  const handleCopyEmbedCode = async () => {
    await navigator.clipboard.writeText(embedCodeString);
    setCopyButtonText("Copied!");
    window.setTimeout(() => {
      setCopyButtonText("Copy embed code");
    }, 2000);
  };

  return (
    <WhereaboutsModal
      isOpen={isOpen}
      onClose={onClose}
      HeaderIcon={Code}
      title={"Embed your booker"}
      description={
        "You’ll find detailed instructions on how to embed your Booker on your preview page"
      }
      size={"lg"}
      customFooter={
        <Flex
          px={"500"}
          py={"400"}
          flex={1}
          gap={"150"}
          justifyContent={"space-between"}
        >
          <Button visual={"outline"} size={"lg"} onClick={handleClose}>
            Cancel
          </Button>
          <Flex gap={"150"}>
            <Button
              Icon={Eye}
              visual={"outline"}
              size={"lg"}
              isIconRightAligned
            >
              Preview
            </Button>
            <Button
              size={"lg"}
              Icon={Code}
              isIconRightAligned
              onClick={handleCopyEmbedCode}
            >
              {copyButtonText}
            </Button>
          </Flex>
        </Flex>
      }
      // Disabling the body padding here instead of using the <Bleed>
      // JSX pattern because `Bleed` didn't work as desired
      disableBodyPadding
    >
      <Flex width={"full"}>
        <Flex
          flexDir={"column"}
          gap={"400"}
          width={"65%"}
          px={"400"}
          py={"300"}
          bg={"bg.wa.lightest"}
        >
          <Flex flexDir={"column"} gap={"100"}>
            <Flex gap={"75"}>
              <Icon Element={Palette} color={"icon.dark"} />
              <Text visual={"bodySemiBold"} color={"text.dark"}>
                Customize
              </Text>
            </Flex>
            <Text color={"text.mid"}>
              Customize the appearance of your Booker button with the options
              below.
            </Text>
          </Flex>

          <Flex flexDir={"column"} gap={"150"}>
            <Input
              name={"text"}
              label={"Button text"}
              bg={"white"}
              onChange={e => onUpdateEmbedStyle(e.currentTarget.value, "text")}
              value={style.text}
            />

            <Flex gap={"100"} flex={1}>
              <ColorPicker
                label={"Background color"}
                value={style.bgColor}
                flex={1}
                css={{ "& input": { bg: "white" } }}
                onValueChange={v =>
                  onUpdateEmbedStyle(v.valueAsString, "bgColor")
                }
              />
              <ColorPicker
                label={"Text color"}
                value={style.color}
                flex={1}
                css={{ "& input": { bg: "white" } }}
                onValueChange={v =>
                  onUpdateEmbedStyle(v.valueAsString, "color")
                }
              />
            </Flex>

            <Flex gap={"100"} flex={1}>
              <Box position={"relative"} width={"full"} flex={1}>
                <Input
                  name={"borderRadius"}
                  label={"Rounded corners"}
                  type={"number"}
                  bg={"white"}
                  onChange={e =>
                    onUpdateEmbedStyle(e.currentTarget.value, "borderRadius")
                  }
                  value={style.borderRadius}
                />
                <Box
                  position={"absolute"}
                  top={"32px"}
                  right={"100"}
                  bg={"white"}
                  pl={"50"}
                  pr={"25"}
                >
                  <Text as={"span"} color={"text.mid"} visual={"smallMedium"}>
                    px
                  </Text>
                </Box>
              </Box>
              <Select.Root
                items={bookerPositionOptions}
                visual="outline"
                css={{
                  "& [data-scope='select'][data-part='trigger']": {
                    bg: "white",
                  },
                }}
                flex={1}
                gap={"50"}
                label={"Booker position"}
                size={"md"}
                onValueChange={({ value }: { value: string[] }) =>
                  onUpdateEmbedStyle(value[0], "bookerPosition")
                }
                value={[style.bookerPosition]}
              >
                {bookerPositionOptions.map(item => (
                  <Select.Item item={item} />
                ))}
              </Select.Root>
            </Flex>
            <Box width={"full"}>
              <Text visual={"smallMedium"} color={"text.mid"} mb={"50"}>
                Button preview
              </Text>
              <Button
                size={"lg"}
                width={"full"}
                style={{
                  background:
                    style.bgColor.length > 0 ? style.bgColor : undefined,
                  color: style.color.length > 0 ? style.color : undefined,
                  borderRadius:
                    style.borderRadius.length > 0 && style.borderRadius !== "8"
                      ? `${style.borderRadius}px`
                      : undefined,
                }}
              >
                {style.text}
              </Button>
            </Box>
          </Flex>
        </Flex>

        <Flex
          width={"35%"}
          flexDir={"column"}
          gap={"150"}
          px={"400"}
          py={"300"}
          borderLeftWidth={"1px"}
          borderLeftColor={"border.light"}
        >
          <Flex gap={"75"}>
            <Icon Element={Code} color={"icon.dark"} />
            <Text visual={"bodySemiBold"} color={"text.dark"}>
              Embed Code
            </Text>
          </Flex>
          <Text
            color={"text.mid"}
            fontSize={"sm"}
            lineHeight={"sm"}
            fontFamily={"mono"}
            width={"full"}
          >
            {embedCodeString}
          </Text>
        </Flex>
      </Flex>
    </WhereaboutsModal>
  );
};
