import { GetOperatorQuery } from "../../../../../../gql/generated";
import {
  OpenHoursOptionsSchema,
  ProfileSchema,
  profileDefaultValues,
} from "./schema";

export const profileMapper = (input: GetOperatorQuery): ProfileSchema => {
  const {
    operator_name,
    website,
    phone,
    email,
    line_1,
    line_2,
    city,
    province,
    postal_code,
    address,
    latitude,
    longitude,
    description,
    short_description,
    tags,
    facebook_link,
    instagram_link,
    youtube_link,
    pinterest_link,
    google_mybusiness_link,
    tiktok_link,
    tripadvisor_link,
    hours_of_operation_week,
    up_to_date_source,
    open_hours_option,
    checklist,
    publishingOptions,
    markAsNeedsAttention,
    seasonality,
    sta_license_status,
    status,
    note,
  } = profileDefaultValues;
  return {
    operator_name: input.getOperator?.operator_name || operator_name,
    website: input.getOperator?.website || website,
    phone: input.getOperator?.phone || phone,
    email: input.getOperator?.email || email,
    line_1: input.getOperator?.line_1 || line_1,
    line_2: input.getOperator?.line_2 || line_2,
    city: input.getOperator?.city || city,
    province: input.getOperator?.province || province,
    postal_code: input.getOperator?.postal_code || postal_code,
    address: input.getOperator?.address || address,
    latitude: input.getOperator?.latitude || latitude,
    longitude: input.getOperator?.longitude || longitude,
    description: {
      en: input.getOperator?.description?.en || description.en,
      fr: input.getOperator?.description?.fr || description.fr,
    },
    short_description: {
      en: input.getOperator?.short_description?.en || short_description.en,
      fr: input.getOperator?.short_description?.fr || short_description.fr,
    },
    tags: {
      type_of_operator:
        input.getOperator?.tags?.type_of_operator || tags.type_of_operator,
      capacity: input.getOperator?.tags?.capacity || tags.capacity,
      region: input.getOperator?.tags?.region || tags.region,
      pricing: input.getOperator?.tags?.pricing || tags.pricing,
      amenities: input.getOperator?.tags?.amenities || tags.amenities,
      vtc_custom: input.getOperator?.tags?.vtc_custom || tags.vtc_custom,
      accreditations:
        input.getOperator?.tags?.accreditations || tags.accreditations,
    },
    facebook_link: input.getOperator?.facebook_link || facebook_link,
    instagram_link: input.getOperator?.instagram_link || instagram_link,
    youtube_link: input.getOperator?.youtube_link || youtube_link,
    pinterest_link: input.getOperator?.pinterest_link || pinterest_link,
    google_mybusiness_link:
      input.getOperator?.google_mybusiness_link || google_mybusiness_link,
    tiktok_link: input.getOperator?.tiktok_link || tiktok_link,
    tripadvisor_link: input.getOperator?.tripadvisor_link || tripadvisor_link,
    hours_of_operation_week: {
      sun: {
        from:
          input.getOperator?.hours_of_operation_week?.sun?.from ||
          hours_of_operation_week.sun.from,
        to:
          input.getOperator?.hours_of_operation_week?.sun?.to ||
          hours_of_operation_week.sun.to,
        isActive:
          input.getOperator?.hours_of_operation_week?.sun?.isActive ||
          hours_of_operation_week.sun.isActive,
      },
      mon: {
        from:
          input.getOperator?.hours_of_operation_week?.mon?.from ||
          hours_of_operation_week.mon.from,
        to:
          input.getOperator?.hours_of_operation_week?.mon?.to ||
          hours_of_operation_week.mon.to,
        isActive:
          input.getOperator?.hours_of_operation_week?.mon?.isActive ||
          hours_of_operation_week.mon.isActive,
      },
      tue: {
        from:
          input.getOperator?.hours_of_operation_week?.tue?.from ||
          hours_of_operation_week.tue.from,
        to:
          input.getOperator?.hours_of_operation_week?.tue?.to ||
          hours_of_operation_week.tue.to,
        isActive:
          input.getOperator?.hours_of_operation_week?.tue?.isActive ||
          hours_of_operation_week.tue.isActive,
      },
      wed: {
        from:
          input.getOperator?.hours_of_operation_week?.wed?.from ||
          hours_of_operation_week.wed.from,
        to:
          input.getOperator?.hours_of_operation_week?.wed?.to ||
          hours_of_operation_week.wed.to,
        isActive:
          input.getOperator?.hours_of_operation_week?.wed?.isActive ||
          hours_of_operation_week.wed.isActive,
      },
      thu: {
        from:
          input.getOperator?.hours_of_operation_week?.thu?.from ||
          hours_of_operation_week.thu.from,
        to:
          input.getOperator?.hours_of_operation_week?.thu?.to ||
          hours_of_operation_week.thu.to,
        isActive:
          input.getOperator?.hours_of_operation_week?.thu?.isActive ||
          hours_of_operation_week.thu.isActive,
      },
      fri: {
        from:
          input.getOperator?.hours_of_operation_week?.fri?.from ||
          hours_of_operation_week.fri.from,
        to:
          input.getOperator?.hours_of_operation_week?.fri?.to ||
          hours_of_operation_week.fri.to,
        isActive:
          input.getOperator?.hours_of_operation_week?.fri?.isActive ||
          hours_of_operation_week.fri.isActive,
      },
      sat: {
        from:
          input.getOperator?.hours_of_operation_week?.sat?.from ||
          hours_of_operation_week.sat.from,
        to:
          input.getOperator?.hours_of_operation_week?.sat?.to ||
          hours_of_operation_week.sat.to,
        isActive:
          input.getOperator?.hours_of_operation_week?.sat?.isActive ||
          hours_of_operation_week.sat.isActive,
      },
    },
    up_to_date_source:
      input.getOperator?.up_to_date_source || up_to_date_source,
    open_hours_option:
      (input.getOperator?.open_hours_option as OpenHoursOptionsSchema) ||
      open_hours_option,
    checklist: {
      verifyAllLinks:
        input.getOperator?.checklist?.verifyAllLinks ||
        checklist.verifyAllLinks,
      tagYourBusiness:
        input.getOperator?.checklist?.tagYourBusiness ||
        checklist.tagYourBusiness,
      verifyLocation:
        input.getOperator?.checklist?.verifyLocation ||
        checklist.verifyLocation,
      verifyOpeningHours:
        input.getOperator?.checklist?.verifyOpeningHours ||
        checklist.verifyOpeningHours,
      uploadNewHighResPhotos:
        input.getOperator?.checklist?.uploadNewHighResPhotos ||
        checklist.uploadNewHighResPhotos,
      isYourBusinessDescriptionAccurateAndCompelling:
        input.getOperator?.checklist
          ?.isYourBusinessDescriptionAccurateAndCompelling ||
        checklist.isYourBusinessDescriptionAccurateAndCompelling,
    },
    publishingOptions:
      input.getOperator?.publishingOptions || publishingOptions,
    markAsNeedsAttention:
      input.getOperator?.markAsNeedsAttention || markAsNeedsAttention,
    seasonality: {
      high: {
        jan: input.getOperator?.seasonality?.high?.jan || seasonality.high.jan,
        feb: input.getOperator?.seasonality?.high?.feb || seasonality.high.feb,
        mar: input.getOperator?.seasonality?.high?.mar || seasonality.high.mar,
        apr: input.getOperator?.seasonality?.high?.apr || seasonality.high.apr,
        may: input.getOperator?.seasonality?.high?.may || seasonality.high.may,
        jun: input.getOperator?.seasonality?.high?.jun || seasonality.high.jun,
        jul: input.getOperator?.seasonality?.high?.jul || seasonality.high.jul,
        aug: input.getOperator?.seasonality?.high?.aug || seasonality.high.aug,
        sep: input.getOperator?.seasonality?.high?.sep || seasonality.high.sep,
        oct: input.getOperator?.seasonality?.high?.oct || seasonality.high.oct,
        nov: input.getOperator?.seasonality?.high?.nov || seasonality.high.nov,
        dec: input.getOperator?.seasonality?.high?.dec || seasonality.high.dec,
      },
      low: {
        jan: input.getOperator?.seasonality?.low?.jan || seasonality.low.jan,
        feb: input.getOperator?.seasonality?.low?.feb || seasonality.low.feb,
        mar: input.getOperator?.seasonality?.low?.mar || seasonality.low.mar,
        apr: input.getOperator?.seasonality?.low?.apr || seasonality.low.apr,
        may: input.getOperator?.seasonality?.low?.may || seasonality.low.may,
        jun: input.getOperator?.seasonality?.low?.jun || seasonality.low.jun,
        jul: input.getOperator?.seasonality?.low?.jul || seasonality.low.jul,
        aug: input.getOperator?.seasonality?.low?.aug || seasonality.low.aug,
        sep: input.getOperator?.seasonality?.low?.sep || seasonality.low.sep,
        oct: input.getOperator?.seasonality?.low?.oct || seasonality.low.oct,
        nov: input.getOperator?.seasonality?.low?.nov || seasonality.low.nov,
        dec: input.getOperator?.seasonality?.low?.dec || seasonality.low.dec,
      },
      closed: {
        jan:
          input.getOperator?.seasonality?.closed?.jan || seasonality.closed.jan,
        feb:
          input.getOperator?.seasonality?.closed?.feb || seasonality.closed.feb,
        mar:
          input.getOperator?.seasonality?.closed?.mar || seasonality.closed.mar,
        apr:
          input.getOperator?.seasonality?.closed?.apr || seasonality.closed.apr,
        may:
          input.getOperator?.seasonality?.closed?.may || seasonality.closed.may,
        jun:
          input.getOperator?.seasonality?.closed?.jun || seasonality.closed.jun,
        jul:
          input.getOperator?.seasonality?.closed?.jul || seasonality.closed.jul,
        aug:
          input.getOperator?.seasonality?.closed?.aug || seasonality.closed.aug,
        sep:
          input.getOperator?.seasonality?.closed?.sep || seasonality.closed.sep,
        oct:
          input.getOperator?.seasonality?.closed?.oct || seasonality.closed.oct,
        nov:
          input.getOperator?.seasonality?.closed?.nov || seasonality.closed.nov,
        dec:
          input.getOperator?.seasonality?.closed?.dec || seasonality.closed.dec,
      },
    },
    sta_license_status:
      input.getOperator?.sta_license_status || sta_license_status,
    status: input.getOperator?.status || status,
    note: {
      text: input.getOperator?.note?.text || note.text,
      by: input.getOperator?.note?.by || note.by,
      date: input.getOperator?.note?.date || note.date,
      is_public: input.getOperator?.note?.is_public || note.is_public,
    },
  };
};
