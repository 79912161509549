import { Button, Card, Icon, Spinner, Tag, Text } from "@ttc3k/trekker";
import { formatPrice } from "core/utils/formatPrice";
import {
  EnumProductPublishingStatus,
  ProductManyQuery,
  useProductTotalSalesQuery,
} from "gql/generated";
import { EditPencil, MediaImage, NavArrowRight } from "iconoir-react";
import { NavLink } from "react-router-dom";
import { Center, Flex, styled } from "styled-system/jsx";
import { CreateEditUpsellModal } from "apps/library/booker/spaces/products/components/CreateEditUpsellModal";
import { useState } from "react";

type ProductCardProps = {
  product: ProductManyQuery["productMany"][0];
};

export const ProductCard = ({ product }: ProductCardProps) => {
  const isDraft =
    product.publishingStatus === EnumProductPublishingStatus.Draft;
  const { data, loading } = useProductTotalSalesQuery({
    variables: { id: product._id },
  });
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      {product.isUpsellOnly && (
        <CreateEditUpsellModal
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          operatorID={product.operator}
          product={product}
        />
      )}
      <Card.Surface boxShadow={"sm"} bg={isDraft ? "white/50" : "white"}>
        <Card.Hero maxH={"220px"} minW={"full"}>
          {product.images.length > 0 ? (
            <styled.img
              src={product.images[0]?.full_url}
              alt={product.images[0]?.caption?.en ?? ""}
              width={"full"}
              height={"220px"}
              objectFit={"cover"}
              objectPosition={"center"}
            />
          ) : (
            <Center w={"full"} h={"full"} bg={"bg.wa.light"}>
              <Icon Element={MediaImage} size={"lg"} />
            </Center>
          )}
        </Card.Hero>
        <Card.Body>
          <Card.BodyContent alignItems={"flex-start"}>
            <Card.Title mainText={product.name.en ?? ""} subText="" />
            {product.isUpsellOnly ? (
              <Button
                Icon={EditPencil}
                visual={"outline"}
                onClick={() => setIsModalOpen(true)}
              />
            ) : (
              <NavLink to={`update-product/${product._id}`}>
                <Button Icon={NavArrowRight} visual={"outline"} />
              </NavLink>
            )}
          </Card.BodyContent>
          <Card.BodyContent>
            {!product.isUpsellOnly && (
              <>
                {isDraft ? (
                  <Tag visual={"highlight"}>Draft</Tag>
                ) : (
                  <>
                    {loading ? (
                      <Flex gap={"75"} alignItems={"center"}>
                        <Text visual={"bodyMedium"} color={"text.mid"}>
                          Total sales:
                        </Text>
                        <Spinner width={"16px"} height={"16px"} />
                      </Flex>
                    ) : (
                      <Text visual={"bodyMedium"} color={"text.mid"}>
                        Total sales: $
                        {formatPrice(data?.productTotalSales ?? 0)}
                      </Text>
                    )}
                  </>
                )}
              </>
            )}
          </Card.BodyContent>
        </Card.Body>
      </Card.Surface>
    </>
  );
};
