import { motion } from "framer-motion";
import {
  LegacyButton,
  tokens,
  LegacyTypography,
  TypographyVariant,
} from "@ttc3k/ttc-design-system";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Controller,
  useForm,
  SubmitHandler,
  SubmitErrorHandler,
} from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { getLatLng } from "use-places-autocomplete";
import { GoogleMap, Marker, MarkerProps } from "@react-google-maps/api";
import { useNavigate } from "react-router-dom";
import { gql, useMutation } from "@apollo/client";
import { GoogleMaps } from "core/components/shared/GoogleMaps";
import { TextField } from "../../../../components/shared/TextField";
import {
  CreateBusinessSchemaPart2,
  createBusinessPart2DefauldValues,
  createBusinessSchemaPart2,
} from "../../../../schemas/createBusinessSchema.ts";
import { useGetTags } from "../../../../../apps/library/directory/branches/leaves/Profile/utils.ts";
import { useCreateOperatorMutation } from "../../../../../gql/generated.ts";
import { variants } from "../../shared.ts";
import { useOnboardingContext } from "../../Layout.tsx";
import { getAutocompleteAddress } from "../../../../utils/getAutocompleteAddress.ts";

const CREATE_TENANT = gql`
  mutation createTenant(
    $user_id: String
    $operators: String
    $registeredApps: String
  ) {
    createTenant(
      user_id: $user_id
      operators: $operators
      registeredApps: $registeredApps
    ) {
      _id
    }
  }
`;

export const AddAddressToNewBusiness = ({ urlAfterClaimFlow }) => {
  const {
    baseURL,
    saveOnboardingStep,
    successSetOnboardingStatus,
    loadingSetOnboardingStatus,
    errorSetOnboardingStatus,
    customPortalData,
  } = useOnboardingContext();

  const { user } = useAuth0();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [coord, setCoord] = useState<{ lat: number; lng: number } | null>(null);
  const { data: tags } = useGetTags([
    { type: "TYPE_OF_OPERATOR", dbName: "Core", collectionName: "tagsohto" },
  ]);
  const [createTenant, { data: created }] = useMutation(CREATE_TENANT);
  const [createOperator, { data: operatorData }] = useCreateOperatorMutation();
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    reset,
    formState: { isSubmitting },
  } = useForm({
    resolver: zodResolver(createBusinessSchemaPart2),
    defaultValues: createBusinessPart2DefauldValues,
  });

  const latitude = watch("latitude");
  const longitude = watch("longitude");
  const onSubmit: SubmitHandler<CreateBusinessSchemaPart2> = async formData => {
    if (!user?.sub) return;
    const part1 = localStorage.getItem("onboardingCreatedBusinessformData");
    if (!part1) return;
    const formDataPart1 = JSON.parse(part1);

    try {
      await createOperator({
        variables: {
          input: {
            name: formDataPart1.name,
            email: formDataPart1.email,
            localPhone: formDataPart1.localPhone,
            tollFreePhone: formDataPart1.tollFreePhone,
            website: formDataPart1.website,
            type: formDataPart1.type,
            address: formData.address,
            latitude: formData.latitude,
            longitude: formData.longitude,
            tenantId: user.sub,
            province: formData.province,
            city: formData.city,
            line1: formData.line1,
            postal_code: formData.postal_code,
            country: formData.country,
            orgTenantId: customPortalData.settings.tenants[0],
          },
        },
      })
        .then(async r => {
          await createTenant({
            variables: {
              user_id: user?.sub,
              operators: JSON.stringify([r.data.createOperator._id]),
              registeredApps: JSON.stringify(
                customPortalData?.settings?.registeredApps,
              ),
            },
          });
          return r.data.createOperator._id;
        })
        .then(r => {
          saveOnboardingStep({
            variables: {
              id: user.sub,
              page: `${baseURL}/${urlAfterClaimFlow}`,
              type: "NEW_BUSINESS",
            },
          });
          return r;
        })
        .then(r => {
          localStorage.removeItem("onboardingCreatedBusinessformData");
          localStorage.removeItem("onboardingCreatedBusinessLocationformData");
          navigate(`${baseURL}/${urlAfterClaimFlow}`, {
            state: { operatorId: r },
          });
        });
    } catch (err) {
      console.error(err);
    }
  };

  const onSubmitError: SubmitErrorHandler<
    CreateBusinessSchemaPart2
  > = errors => {
    console.error(errors);
  };

  const handleDrag: MarkerProps["onDragEnd"] = e => {
    setValue("latitude", e.latLng?.lat() || 0, {
      shouldDirty: true,
      shouldValidate: true,
    });
    setValue("longitude", e.latLng?.lng() || 0);

    localStorage.setItem(
      "onboardingCreatedBusinessLocationformData",
      JSON.stringify({
        address: getValues("address"),
        latitude: e.latLng?.lat() || 0,
        longitude: e.latLng?.lng() || 0,
        province: getValues("province") || "",
        city: getValues("city") || "",
        line1: getValues("line1") || "",
        postal_code: getValues("postal_code") || "",
        country: getValues("country") || "",
      }),
    );
  };

  const handleSelect = (arg: google.maps.GeocoderResult | null) => {
    if (!arg) {
      reset(createBusinessPart2DefauldValues);
      localStorage.setItem(
        "onboardingCreatedBusinessLocationformData",
        JSON.stringify(createBusinessPart2DefauldValues),
      );
      return;
    }

    const obj = getAutocompleteAddress(arg);

    const { lat, lng } = getLatLng(arg);
    setValue("address", arg.formatted_address, {
      shouldDirty: true,
      shouldValidate: true,
    });
    setValue("latitude", lat);
    setValue("longitude", lng);
    setValue("country", obj.country);
    setValue("city", obj.city);
    setValue("line1", obj.line1);
    setValue("province", obj.province);
    setValue("postal_code", obj.postal_code);
    setCoord({ lat, lng });

    localStorage.setItem(
      "onboardingCreatedBusinessLocationformData",
      JSON.stringify({
        address: arg.formatted_address,
        latitude: lat,
        longitude: lng,
        ...obj,
      }),
    );
  };

  useEffect(() => {
    const createdBusinessInfo = localStorage.getItem(
      "onboardingCreatedBusinessLocationformData",
    );
    if (createdBusinessInfo) {
      const initialValue = JSON.parse(createdBusinessInfo);
      reset(initialValue);
      setCoord({ lat: initialValue.latitude, lng: initialValue.longitude });
    }
  }, []);

  return (
    <motion.div
      key={page}
      className="tw-flex tw-w-full md:tw-w-96 tw-mt-auto tw-flex-col tw-gap-6"
      variants={variants}
      initial="enter"
      animate="center"
      exit="exit"
    >
      <LegacyTypography
        variant={TypographyVariant.h4}
        color={tokens.semantic.colorText}
        className="tw-text-center"
      >
        Step 2 - Location
      </LegacyTypography>
      <LegacyTypography
        variant={TypographyVariant.primaryRegular}
        color={tokens.semantic.colorText}
        className="tw-text-center"
      >
        Enter the address for you business or organization below. If needed, you
        can adjust the longitude and latitude values or move the pin in the map
        to a more precise location.
      </LegacyTypography>

      <div className="tw-flex tw-flex-col tw-gap-3">
        <Controller
          name="address"
          control={control}
          render={({ field }) => (
            <GoogleMaps.PlacesAutocomplete
              label="Search by Business Name or Address"
              onChange={handleSelect}
              value={field.value}
            />
          )}
        />
        <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-[12px]">
          <Controller
            name="latitude"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label="Longitude"
                error={!!fieldState.error?.message}
                helperText={fieldState.error?.message}
                type="number"
              />
            )}
          />
          <Controller
            name="longitude"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label="Latitude"
                error={!!fieldState.error?.message}
                helperText={fieldState.error?.message}
                type="number"
              />
            )}
          />
        </div>
        <GoogleMap
          mapContainerStyle={{
            width: "100%",
            height: "235px",
            borderRadius: "8px",
          }}
          center={
            { lat: Number(latitude), lng: Number(longitude) } ?? {
              lat: 0,
              lng: 0,
            }
          }
          zoom={12}
          options={{
            disableDefaultUI: true,
            mapTypeControl: false,
          }}
        >
          {coord && (
            <Marker
              position={{ lat: Number(latitude), lng: Number(longitude) }}
              draggable
              onDragEnd={handleDrag}
            />
          )}
        </GoogleMap>
      </div>

      <div className="tw-w-full tw-flex tw-justify-between tw-gap-3">
        <LegacyButton
          onClick={handleSubmit(onSubmit, onSubmitError)}
          type="button"
          fullWidth
          isLoading={isSubmitting}
        >
          Save
        </LegacyButton>
      </div>
    </motion.div>
  );
};
