import { LegacyPageHeader } from "@ttc3k/ttc-design-system";
import {
  ChatBubbleQuestion,
  Page,
  MapPinXmark,
  Calendar,
  Apple,
  DollarCircleSolid,
} from "iconoir-react";
import { cn } from "../../utils/cn";
import ActionButton, { ActionButtonProps } from "./ActionButton";
import { Breadcrumbs } from "./Breadcrumbs/Breadcrumbs";
import { crumbs as crumbsType } from "./PreferredLayout";

type HeaderProps = {
  title?: string;
  description?: string | JSX.Element;
  actionButton: ActionButtonProps | null;
  icon?: string;
  crumbs: crumbsType;
  button: JSX.Element;
};

export default function Header({
  title,
  description,
  actionButton,
  icon,
  crumbs,
  button,
}: HeaderProps) {
  return (
    <div className="tw-flex tw-flex-col tw-gap-2 tw-max-w-[1200px] tw-m-auto">
      <Breadcrumbs crumbs={crumbs} />
      <div
        className={
          "tw-flex tw-justify-between tw-items-center tw-grow-0 tw-relative"
        }
      >
        <div
          className={cn("tw-w-1/2", { "tw-w-full": !actionButton && !button })}
        >
          <LegacyPageHeader
            title={title ?? ""}
            icon={(() => {
              switch (icon) {
                case "PAGE":
                  return <Page width={30} height={30} strokeWidth={2} />;
                case "CALENDAR":
                  return <Calendar width={30} height={30} strokeWidth={2} />;
                case "DOLLAR":
                  return (
                    <DollarCircleSolid width={30} height={30} strokeWidth={2} />
                  );
                case "APPLE":
                  return <Apple width={30} height={30} strokeWidth={2} />;
                case "HELP":
                  return (
                    <ChatBubbleQuestion
                      width={30}
                      height={30}
                      strokeWidth={2}
                    />
                  );
                case "MAP":
                  return <MapPinXmark width={30} height={30} strokeWidth={2} />;
                case "NONE":
                  return null;
              }
            })()}
            description={description}
          />
        </div>
        {actionButton && <ActionButton {...actionButton} />}
        {button && button}
      </div>
    </div>
  );
}
