import { Button, Spinner, Text, toastFactory } from "@ttc3k/trekker";
import { useAppContext } from "core/components/apps/PreferredLayout";
import { BookerLeafHeader } from "core/components/shared/BookerLeafHeader";
import {
  BookerByMainProductIdDocument,
  BookerByMainProductIdQuery,
  ProductManyQuery,
  useBookerUpdateMutation,
  useProductManyQuery,
} from "gql/generated";
import { FormEvent, useCallback, useEffect, useState } from "react";
import { Box, Flex, Grid, styled } from "styled-system/jsx";
import { removeTypename } from "core/utils/removeTypename";
import _ from "lodash";
import { CreateEditUpsellModal } from "../../../components";
import { ProductMenuButton } from "../../components";
import { UpsellCard } from "./components";

type AccommodationUpsellsLeafProps = {
  productID: string;
  operatorID: string;
  booker: BookerByMainProductIdQuery["bookerByMainProductId"];
};

export const AccommodationUpsellsLeaf = ({
  productID,
  operatorID,
  booker,
}: AccommodationUpsellsLeafProps) => {
  const defaultUpsellIDs: string[] =
    booker?.productBundles.find(bundle => bundle?.product === productID)
      ?.addOns ?? [];

  const [currentUpsell, setCurrentUpsell] = useState<
    ProductManyQuery["productMany"][0] | undefined
  >(undefined);
  const [openNewUpsellModal, setOpenNewUpsellModal] = useState(false);
  const [selectedUpsellIDs, setSelectedUpsellIDs] =
    useState<string[]>(defaultUpsellIDs);

  const { data, loading, error } = useProductManyQuery({
    variables: {
      filter: { operator: operatorID, isActive: true, isUpsellOnly: true },
    },
  });
  const { setActionSection } = useAppContext();
  const [updateBooker] = useBookerUpdateMutation();

  const cleanedBookerData = removeTypename(booker);
  const handleSave = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      updateBooker({
        variables: {
          id: booker?._id ?? "",
          record: {
            productBundles: cleanedBookerData?.productBundles.map(bundle => {
              if (bundle?.product === productID) {
                return { ...bundle, addOns: selectedUpsellIDs };
              } else return bundle;
            }),
          },
        },
        refetchQueries: [BookerByMainProductIdDocument],
      })
        .then(() => {
          toastFactory.create({
            title: "Success!",
            description: "Your upsells were updated successfully",
          });
        })
        .catch(() => {
          toastFactory.create({
            title: "Error",
            description:
              "We could not update your upsells, please try again later",
          });
        });
    },
    [
      booker?._id,
      cleanedBookerData?.productBundles,
      productID,
      selectedUpsellIDs,
      updateBooker,
    ],
  );

  const formID = "update-accommodation-upsells";
  const isDirty = !_.isEmpty(_.xor(defaultUpsellIDs, selectedUpsellIDs));

  useEffect(() => {
    setActionSection(
      <Flex gap={"150"}>
        <ProductMenuButton productID={productID} />
        <Button size={"lg"} form={formID} type={"submit"} disabled={!isDirty}>
          Save
        </Button>
      </Flex>,
    );

    return () => setActionSection(undefined);
  }, [isDirty, productID, setActionSection]);

  const handleSelectUpsell = (id: string) => {
    const isCurrentlySelected = selectedUpsellIDs.includes(id);
    if (isCurrentlySelected) {
      setSelectedUpsellIDs(prev => prev.filter(i => i !== id));
    } else {
      setSelectedUpsellIDs(prev => [...prev, id]);
    }
  };

  const handleCloseModal = () => {
    setCurrentUpsell(undefined);
    setOpenNewUpsellModal(false);
  };

  if (loading) {
    return (
      <Box width={"full"}>
        <Spinner width={"50px"} height={"50px"} margin={"60px auto"} />
      </Box>
    );
  }
  if (!booker) {
    return (
      <Text
        marginY={"60px"}
        width={"full"}
        textAlign={"center"}
        color={"text.error.mid"}
      >
        Error : Couldn't load data properly, please try again later
      </Text>
    );
  }
  if (error) {
    return (
      <Text
        marginY={"60px"}
        width={"full"}
        textAlign={"center"}
        color={"text.error.mid"}
      >
        Error : {error?.message}
      </Text>
    );
  }

  return (
    <>
      <form id={formID} onSubmit={handleSave}>
        <Flex flexDir={"column"} gap={"300"} width={"full"}>
          <BookerLeafHeader
            title={"Upsells"}
            description={
              "Any fee-based product you’ve created can be attached to this product as an add-on, or you can create a new one from scratch. If you create a new upsell here, we’ll save it so you can use it for any other product!"
            }
          />
          <Grid
            gap={"200"}
            gridTemplateColumns={{
              base: "repeat(1, 1fr)",
              xl: "repeat(2, 1fr)",
            }}
          >
            {data?.productMany.map(p => {
              const selected = selectedUpsellIDs.includes(p._id);
              return (
                <UpsellCard
                  key={p._id}
                  product={p}
                  onEditClick={() => setCurrentUpsell(p)}
                  onSelectUpsell={() => handleSelectUpsell(p._id)}
                  visual={selected ? "selected" : "neutral"}
                />
              );
            })}
            <styled.button
              borderWidth={"1px"}
              borderStyle={"dashed"}
              borderColor={"border.mid"}
              minWidth={"280px"}
              padding={"300"}
              borderRadius={"100"}
              bg={"bg.wa.light"}
              textAlign={"left"}
              gridColumnStart={1}
              onClick={() => setOpenNewUpsellModal(true)}
              type={"button"}
            >
              <Text visual={"bodyMedium"} color={"text.accent.dark"}>
                + Create new upsell
              </Text>
            </styled.button>
          </Grid>
        </Flex>
      </form>
      <CreateEditUpsellModal
        open={currentUpsell !== undefined || openNewUpsellModal}
        onClose={handleCloseModal}
        operatorID={operatorID}
        product={currentUpsell}
        onSuccess={handleSelectUpsell}
      />
    </>
  );
};
