import {
  LegacyAppTabs,
  LegacyPageDivider,
  LegacyTypography,
  Tab,
  TypographyVariant,
  tokens,
} from "@ttc3k/ttc-design-system";
import { Xmark } from "iconoir-react";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import _ from "lodash";
import { ConfigSpace } from "./PreferredLayout";

type AppMenu = {
  title?: string;
  slug: string;
  spaces?: ConfigSpace[];
};

export default function AppMenu({ title, slug, spaces }: AppMenu) {
  const { spaceId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const spacesTabs: Tab[] =
    spaces?.map(s => ({
      id: s.name.toLowerCase(),
      name: s.name,
      onClick: () =>
        navigate(`/app/${s.appName}/${spaceId}/${s.name.toLowerCase()}`),
    })) ?? [];

  const currentSpace = _.nth(location.pathname.split("/"), 4);

  return (
    <div className={"tw-z-10"}>
      <div className="tw-flex tw-items-center tw-justify-between tw-bg-white tw-grow-0 tw-relative tw-px-8 tw-py-3">
        <NavLink to={slug}>
          <LegacyTypography
            variant={TypographyVariant.tertiaryLabel}
            color={tokens.semantic.textSubtlest}
          >
            {title}
          </LegacyTypography>
        </NavLink>
        {spaces && <LegacyAppTabs tabs={spacesTabs} value={currentSpace} />}
        <div className={"tw-flex tw-gap-3"}>
          <NavLink to={"/"}>
            <Xmark
              width={20}
              height={20}
              strokeWidth={1.75}
              color={tokens.semantic.iconSubtlest}
            />
          </NavLink>
        </div>
        {/*<Status />*/}
      </div>
      <LegacyPageDivider />
    </div>
  );
}
