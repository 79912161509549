import { useFormHandler } from "core/hooks/useFormHandler";
import { removeTypename } from "core/utils/removeTypename";
import {
  EnumPriceTierType,
  EnumProductPublishingStatus,
  ProductByIdDocument,
  useProductByIdQuery,
  useProductUpdateMutation,
} from "gql/generated";
import _ from "lodash";
import { nanoid } from "nanoid";
import { useCallback, useMemo } from "react";
import { z } from "zod";
import { guestSchema } from "./useAddEditGuestDataForm";

const updateAccomFormSchema = z.object({
  price: z
    .string()
    .transform(val => parseFloat(val))
    .refine(val => !isNaN(val) && val >= 0, {
      message: "Please enter a positive number",
    })
    .transform(val => val.toString()),
  guests: z.array(guestSchema).min(1, "Please add atleast one guest type"),
  minimum: z
    .string()
    .refine(
      val => val === "" || (!isNaN(parseFloat(val)) && parseFloat(val) >= 1),
      {
        message: "Please enter a number above 0",
      },
    )
    .transform(val => (val === "" ? "" : parseFloat(val).toString())),
  maximum: z
    .string()
    .refine(
      val => val === "" || (!isNaN(parseFloat(val)) && parseFloat(val) >= 0),
      {
        message: "Please enter a positive number",
      },
    )
    .transform(val => (val === "" ? "" : parseFloat(val).toString())),
});

export type UpdateAccomPriceFormValues = z.infer<typeof updateAccomFormSchema>;

interface UpdateAccomFormOptions {
  onSuccess?: (v: string) => void;
  onError?: (message: string) => void;
  productID: string;
}

export const useUpdateAccomPriceForm = ({
  onSuccess,
  onError,
  productID,
}: UpdateAccomFormOptions) => {
  const { data: productData } = useProductByIdQuery({
    variables: { id: productID },
  });

  const cleanedData = removeTypename(productData);

  const defaultPrice = productData?.productById?.pricing.tiers.find(t =>
    t?.price ? t.price > 0 : false,
  )?.price;

  const defaultValues: UpdateAccomPriceFormValues = useMemo(
    () => ({
      price: defaultPrice ? (defaultPrice / 100).toString() : "0",
      guests:
        productData?.productById?.pricing.tiers.map(t => ({
          label: t?.label?.en ?? "",
          type: t?.type ?? EnumPriceTierType.Adult,
          minimum: t?.minimumQuantity ? t.minimumQuantity.toString() : "",
          maximum: t?.maximumQuantity ? t.maximumQuantity.toString() : "",
          _id: nanoid(),
        })) ?? [],
      minimum: productData?.productById?.accommodationMetadata?.guestMetadata
        .minimumNumber
        ? productData?.productById?.accommodationMetadata?.guestMetadata.minimumNumber.toString()
        : "1",
      maximum: productData?.productById?.accommodationMetadata?.guestMetadata
        .maximumNumber
        ? productData?.productById?.accommodationMetadata?.guestMetadata.maximumNumber.toString()
        : "",
    }),
    [
      defaultPrice,
      productData?.productById?.accommodationMetadata?.guestMetadata
        .maximumNumber,
      productData?.productById?.accommodationMetadata?.guestMetadata
        .minimumNumber,
      productData?.productById?.pricing.tiers,
    ],
  );

  const [updateProduct] = useProductUpdateMutation();
  return useFormHandler<UpdateAccomPriceFormValues>(
    useCallback(
      async data => {
        const payload = { ...data };
        const { price, guests, maximum, minimum } = payload;

        const formErrorMessage =
          "Could not update this product, please try again later";

        if (productID && productData && productData?.productById) {
          const updatedPriceTiers = _.compact(
            guests?.map(g => ({
              label: { en: g?.label ?? "", es: "", fr: "" },
              type: (g?.type as EnumPriceTierType) ?? EnumPriceTierType.Adult,
              maximumQuantity:
                typeof g?.maximum === "string" && g.maximum.length > 0
                  ? Number(g.maximum)
                  : undefined,
              minimumQuantity:
                typeof g?.minimum === "string" && g.minimum.length > 0
                  ? Number(g.minimum)
                  : undefined,
              price: price ? Number(price) * 100 : 0,
            })),
          );
          updateProduct({
            variables: {
              id: productID,
              record: {
                pricing: {
                  ...cleanedData?.productById?.pricing,
                  tiers: updatedPriceTiers,
                },
                accommodationMetadata: {
                  ...cleanedData?.productById?.accommodationMetadata,
                  amenities:
                    cleanedData?.productById?.accommodationMetadata?.amenities.map(
                      v => v._id,
                    ),
                  guestMetadata: {
                    ...cleanedData?.productById?.accommodationMetadata
                      ?.guestMetadata,
                    minimumNumber:
                      minimum && minimum.length > 0 ? Number(minimum) : 0,
                    maximumNumber:
                      maximum && maximum.length > 0 ? Number(maximum) : 0,
                  },
                },
                publishingStatus: EnumProductPublishingStatus.Published,
              },
            },
            refetchQueries: [ProductByIdDocument],
          })
            .then(
              () => onSuccess?.(productData.productById?.name.en ?? "Product"),
            )
            .catch(() => onError?.(formErrorMessage));
        } else {
          onError?.(formErrorMessage);
        }
      },
      [
        cleanedData?.productById?.accommodationMetadata,
        cleanedData?.productById?.pricing,
        onError,
        onSuccess,
        productData,
        productID,
        updateProduct,
      ],
    ),
    defaultValues,
    { schema: updateAccomFormSchema },
  );
};
