//@ts-nocheck
import { motion } from "framer-motion";
import {
  LegacyButton,
  Sizes,
  LegacyTypography,
} from "@ttc3k/ttc-design-system";
import { Link, useLocation } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import SlideInPage from "../../animation/SlideInPage.tsx";

export const VideoTemplate = ({ baseURL, next, stepData }) => {
  const { user } = useAuth0();
  const { state } = useLocation();

  return (
    <SlideInPage>
      <div className="tw-flex tw-flex-col tw-gap-5">
        <div className="tw-w-full md:tw-w-96 tw-mx-auto tw-flex tw-flex-col tw-gap-1">
          <LegacyTypography as="h2" variant="H2">
            {stepData.title}
          </LegacyTypography>
          <LegacyTypography>{stepData.preDescription}</LegacyTypography>
        </div>
        <div className="tw-w-full md:tw-w-[800px]">
          <div className="wistia_responsive_padding">
            <div className="wistia_responsive_wrapper">
              <div
                className={`wistia_embed wistia_async_${stepData.video} seo=false videoFoam=true`}
              >
                <div className="wistia_swatch">
                  <img
                    src={`https://fast.wistia.com/embed/medias/${stepData.video}/swatch`}
                    alt=""
                    aria-hidden="true"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="tw-w-full md:tw-w-96 tw-mx-auto tw-flex tw-flex-col tw-gap-1">
          <LegacyTypography>{stepData.postDescription}</LegacyTypography>
          {next && (
            <Link
              state={{ email: state?.email }}
              className={"tw-pt-6"}
              to={`${baseURL}/${next}`}
            >
              <LegacyButton fullWidth size={Sizes.Large}>
                Next
              </LegacyButton>
            </Link>
          )}
        </div>
      </div>
    </SlideInPage>
  );
};
