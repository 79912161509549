// @ts-nocheck
import { lazy, Suspense, useEffect, useState } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { AnimatePresence } from "framer-motion";
import { gql, useQuery } from "@apollo/client";
import * as _ from "lodash";
import { PublicReservationPage } from "core/components/shared/ReservationPage";
import BookerWelcome from "apps/library/booker/Welcome.tsx";
import { OrdersBundleView } from "apps/library/booker/spaces/orders/index.tsx";
import { Layout } from "./core/Layout";
import { Home } from "./core/pages/home/Home";
import { Canvas } from "./core/components/apps/Canvas";
import { PageNotFound } from "./core/pages/404";
import { CallbackPage } from "./core/pages/auth/CallbackPage.tsx";
import { Loader } from "./core/components/Loader";
import Loadable from "./core/utils/loadable.jsx";
import { ProtectedRoute } from "./ProtectedRoute";
import TermsAndConditions from "./core/pages/public/TermsAndConditions";
import TripperPlayground from "./core/pages/public/tripper-launcher-playground/TripperPlayground.jsx";
import { useSiteSettings } from "./SiteSettingsContext.tsx";
import { PostBoardingAcceptInvitation } from "./core/pages/postboarding/PostBoardingAcceptInvitation.tsx";
import PostboardingLayout from "./core/pages/postboarding/PostBoardingLayout.tsx";
import { ProductsBundleView } from "./apps/library/booker/spaces/products/index.tsx";
import { Settings } from "./apps/library/booker/spaces/settings/index.tsx";
import { CalendarBundleView } from "./apps/library/booker/spaces/calendar/index.tsx";
import ProductBranches from "./apps/library/booker/spaces/products/branches/index.tsx";
import CalendarLeafOrderDetails from "./apps/library/booker/spaces/calendar/leaves/index.tsx";

const GET_REGISTERED_APPS = gql`
  query getRegisteredApps($tenant: String!) {
    getTeamInfo(name: $tenant) {
      _id
      name
      org
      registeredApps {
        bundle
        machineName
        name
        path
        slug
        version
      }
    }
  }
`;

const GET_ORG = gql`
  query OrgById($id: MongoID!) {
    orgById(_id: $id) {
      _id
      name
      shortName
    }
  }
`;

// This function dynamically loads the correct component based on the path
function findOutlet(location, registeredApps, user, TYPE) {
  const segments = location.split("/");
  const appPath = "/" + segments[1] + "/" + segments[2];
  const branch = segments[3];
  const branchID = segments[4];
  const leaf = segments[5];
  const found = registeredApps.find(app => appPath == `/app/${app.slug}`);
  const tmp = found?.path.split("/");

  //This logic is to account for a lack of dynamic import support in Vite. Variables must not 'cross' slashes
  //Unfortunately, this means that components can only be nested three levels deep at this time
  switch (TYPE) {
    case "APP":
      if (tmp?.length == 1) {
        const Component = Loadable(
          lazy(() => import(`./apps/${tmp[0]}/Layout.tsx`)),
        );
        return (
          <ProtectedRoute user={user}>
            <Component />
          </ProtectedRoute>
        );
      } else if (tmp?.length == 2) {
        const Component = Loadable(
          lazy(() => import(`./apps/${tmp[0]}/${tmp[1]}/Layout.tsx`)),
        );
        return (
          <ProtectedRoute user={user}>
            <Component />
          </ProtectedRoute>
        );
      } else if (tmp?.length == 3) {
        const Component = Loadable(
          lazy(() => import(`./apps/${tmp[0]}/${tmp[1]}/${tmp[2]}/Layout.tsx`)),
        );
        return (
          <ProtectedRoute user={user}>
            <Component />
          </ProtectedRoute>
        );
      } else {
        return <PageNotFound />;
      }
    case "BRANCH":
      if (tmp?.length == 1) {
        const Component = Loadable(
          lazy(
            () =>
              import(
                `./apps/${tmp[0]}/branches/leaves/${found.bundle.leaves[0].path}.tsx`
              ),
          ),
        );
        return (
          <ProtectedRoute user={user}>
            <Component />
          </ProtectedRoute>
        );
      } else if (tmp?.length == 2) {
        const Component = Loadable(
          lazy(
            () =>
              import(
                `./apps/${tmp[0]}/${tmp[1]}/branches/leaves/${found.bundle.leaves[0].path}.tsx`
              ),
          ),
        );
        return (
          <ProtectedRoute user={user}>
            <Component />
          </ProtectedRoute>
        );
      } else if (tmp?.length == 3) {
        const Component = Loadable(
          lazy(
            () =>
              import(
                `./apps/${tmp[0]}/${tmp[1]}/${tmp[2]}/branches/leaves/${found.bundle.leaves[0].path}.tsx`
              ),
          ),
        );
        return (
          <ProtectedRoute user={user}>
            <Component />
          </ProtectedRoute>
        );
      } else {
        return <PageNotFound />;
      }
    case "LEAF":
      if (tmp?.length == 1) {
        const Component = Loadable(
          lazy(
            () =>
              import(
                `./apps/${tmp[0]}/branches/leaves/${
                  found.bundle.leaves.find(l => leaf == l.slug).path
                }.tsx`
              ),
          ),
        );
        return (
          <ProtectedRoute user={user}>
            <Component />
          </ProtectedRoute>
        );
      } else if (tmp?.length == 2) {
        const Component = Loadable(
          lazy(
            () =>
              import(
                `./apps/${tmp[0]}/${tmp[1]}/branches/leaves/${
                  found.bundle.leaves.find(l => leaf == l.slug).path
                }.tsx`
              ),
          ),
        );
        return (
          <ProtectedRoute user={user}>
            <Component />
          </ProtectedRoute>
        );
      } else if (tmp?.length == 3) {
        const Component = Loadable(
          lazy(
            () =>
              import(
                `./apps/${tmp[0]}/${tmp[1]}/${tmp[2]}/branches/leaves/${
                  found.bundle.leaves.find(l => leaf == l.slug).path
                }.tsx`
              ),
          ),
        );
        return (
          <ProtectedRoute user={user}>
            <Component />
          </ProtectedRoute>
        );
      } else {
        return <PageNotFound />;
      }
  }
}

function getConfig(data) {
  const promises = data.getTeamInfo.registeredApps.map(async app => {
    if (app.version == 2) {
      return {
        ...app,
        bundle: await import(`./apps/library/booker/Layout.tsx`).then(
          module => {
            const { config } = module;
            return config.bundle;
          },
        ),
      };
    } else {
      const segments = app.bundle.split("/");
      if (segments.length == 1) {
        return {
          ...app,
          bundle: await import(`./apps/${segments[0]}/Layout.tsx`).then(
            module => {
              const { config } = module;
              return config.bundle;
            },
          ),
        };
      } else if (segments.length == 2) {
        return {
          ...app,
          bundle: await import(
            `./apps/${segments[0]}/${segments[1]}/Layout.tsx`
          ).then(module => {
            const { config } = module;
            return config.bundle;
          }),
        };
      } else if (segments.length == 3) {
        return {
          ...app,
          bundle: await import(
            `./apps/${segments[0]}/${segments[1]}/${segments[2]}/Layout.tsx`
          ).then(module => {
            const { config } = module;
            return config.bundle;
          }),
        };
      } else {
        throw Error(
          "Config can only be a maximum of 3 levels deep, relative to /app",
        );
      }
    }
  });
  return Promise.all(promises);
}

export default function App() {
  const { user } = useAuth0();
  const siteSettings = useSiteSettings();
  const maintenanceMode = siteSettings.maintenanceMode;
  const [registeredApps, setRegisteredApps] = useState(null);
  const [orgId, setOrgId] = useState(null);
  const { loading, error, data } = useQuery(GET_REGISTERED_APPS, {
    variables: { tenant: user?.whereaboutsTenants[0] },
  });
  const {
    loading: orgLoading,
    error: orgError,
    data: orgData,
  } = useQuery(GET_ORG, {
    variables: {
      id: orgId,
      skip: !orgId,
    },
  });
  const location = useLocation();

  //Inject config into registeredApps Object
  useEffect(() => {
    if (data) {
      getConfig(data).then(result => setRegisteredApps(result));
      setOrgId(data?.getTeamInfo?.org);
    }
  }, [data]);

  if (error) {
    return <div>Oops... problem getting your data ... {error.message}</div>;
  }

  if (loading) {
    return (
      <div className="tw-h-screen tw-flex tw-items-center tw-justify-center">
        <Loader type="LOGO" />
      </div>
    );
  }

  if (_.isEmpty(registeredApps)) {
    return (
      <div className="tw-h-screen tw-flex tw-items-center tw-justify-center">
        <Loader type="LOGO" />
      </div>
    );
  }

  //const previousLocation = location.state?.previousLocation;
  const previousLocation = !location.pathname.includes("app")
    ? location?.state?.previousLocation
    : {
        pathname: "/",
        search: "",
        hash: "",
        state: null,
        key: "default",
      };
  const locationArr = location.pathname?.split("/") ?? [];

  if (location.pathname.includes("onboarding")) {
    return <Navigate to={"/"} />;
  }
  return (
    <div>
      <Routes location={previousLocation || location}>
        <Route path="/" element={<Layout />}>
          <Route
            index
            element={
              <ProtectedRoute user={user}>
                <Home
                  banner={maintenanceMode?.banner}
                  registeredApps={registeredApps}
                  organization={orgData?.orgById}
                  previousLocation={previousLocation}
                />
              </ProtectedRoute>
            }
          />
        </Route>
        <Route path="/open" element={<Layout />}>
          <Route path="terms-and-conditions" element={<TermsAndConditions />} />
          <Route
            path="tripper-playground/:slug"
            element={<TripperPlayground />}
          />
        </Route>
        <Route path="invites" element={<PostboardingLayout user={user} />}>
          <Route
            path="accept"
            element={<PostBoardingAcceptInvitation urlAfterClaimFlow="/" />}
          />
        </Route>
        <Route path="/callback" element={<CallbackPage />} />
        <Route path="/sign-in" element={<Navigate to={"/"} />} />
        <Route path="/sign-up" element={<Navigate to={"/"} />} />
        <Route
          path="/booker/reservation/:id"
          element={<PublicReservationPage />}
        />
        <Route path="/*" element={<PageNotFound />} />
      </Routes>

      {previousLocation && (
        <AnimatePresence>
          <Suspense fallback={<div>Loading...</div>}>
            <Routes location={location} key={locationArr[2]}>
              <Route
                path="/app"
                element={
                  <ProtectedRoute user={user}>
                    <Canvas />
                  </ProtectedRoute>
                }
              >
                <Route
                  path=":appId"
                  element={findOutlet(
                    location.pathname,
                    registeredApps,
                    user,
                    "APP",
                  )}
                >
                  {location.pathname.includes("booker") ? (
                    <>
                      <Route
                        path=""
                        element={
                          <AnimatePresence>
                            <Routes location={location} key={locationArr[2]}>
                              <Route
                                path="/*"
                                element={<BookerWelcome />}
                              ></Route>
                            </Routes>
                          </AnimatePresence>
                        }
                      />
                      <Route
                        path=":spaceId/products/*"
                        element={
                          <AnimatePresence>
                            <Routes location={location} key={locationArr[3]}>
                              <Route
                                path="/*"
                                element={<ProductsBundleView />}
                              ></Route>
                            </Routes>
                          </AnimatePresence>
                        }
                      />
                      <Route
                        path=":spaceId/settings/*"
                        element={
                          <AnimatePresence>
                            <Routes location={location} key={locationArr[3]}>
                              <Route path="/*" element={<Settings />}></Route>
                            </Routes>
                          </AnimatePresence>
                        }
                      />
                      <Route
                        path=":spaceId/calendar/*"
                        element={
                          <AnimatePresence>
                            <Routes location={location} key={locationArr[3]}>
                              <Route
                                path="/*"
                                element={<CalendarBundleView />}
                              ></Route>
                            </Routes>
                          </AnimatePresence>
                        }
                      />
                      <Route
                        path=":spaceId/orders/*"
                        element={
                          <AnimatePresence>
                            <Routes location={location} key={locationArr[3]}>
                              <Route
                                path="/*"
                                element={<OrdersBundleView />}
                              ></Route>
                            </Routes>
                          </AnimatePresence>
                        }
                      />
                      <Route
                        path=":spaceId/products/:branch/:id/*"
                        element={
                          <AnimatePresence>
                            <Routes location={location} key={locationArr[3]}>
                              <Route path="/*" element={<ProductBranches />} />
                            </Routes>
                          </AnimatePresence>
                        }
                      />
                      <Route
                        path=":spaceId/calendar/:branch/:id/*"
                        element={
                          <AnimatePresence>
                            <Routes location={location} key={locationArr[3]}>
                              <Route
                                path="/*"
                                element={<CalendarLeafOrderDetails />}
                              />
                            </Routes>
                          </AnimatePresence>
                        }
                      />
                    </>
                  ) : (
                    <Route
                      path=":branch/:id/*"
                      element={
                        <AnimatePresence>
                          <Routes location={location} key={locationArr[3]}>
                            <Route
                              path="/*"
                              element={findOutlet(
                                location.pathname,
                                registeredApps,
                                user,
                                "BRANCH",
                              )}
                            />
                          </Routes>
                        </AnimatePresence>
                      }
                    />
                  )}
                  <Route
                    path=":branch/:id/:leaf"
                    element={
                      <AnimatePresence>
                        <Routes location={location} key={locationArr[3]}>
                          <Route
                            path="/*"
                            element={findOutlet(
                              location.pathname,
                              registeredApps,
                              user,
                              "LEAF",
                            )}
                          />
                        </Routes>
                      </AnimatePresence>
                    }
                  />
                </Route>
              </Route>
            </Routes>
          </Suspense>
        </AnimatePresence>
      )}
    </div>
  );
}
