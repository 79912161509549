import * as React from "react";
import { motion } from "framer-motion";
import { Outlet, useOutletContext } from "react-router-dom";
import { useRef } from "react";
import { LegacyPageDivider } from "@ttc3k/ttc-design-system";
import { Box } from "styled-system/jsx";
import { ActionButtonProps } from "core/components/apps/ActionButton";
import AppMenu from "./AppMenu";
import Header from "./Header";
import { Breadcrumb } from "./Breadcrumbs/Breadcrumbs";
import { SpacesHeaders } from "./SpacesHeaders";

export type crumbs = Breadcrumb[];

export type ConfigLeaf = {
  name: string;
  slug: string;
  path: string;
};

export type ConfigBundle = {
  branches: { path: string };
  leaves: ConfigLeaf[];
};

export type ConfigSpace = {
  id: string;
  name: string;
  appName: string;
  onClick?: () => void;
  bundle: {
    branches: { path: string };
  };
};

export type Config = {
  machineName: string;
  appName: string;
  dockName: string;
  crumbName: string;
  shortDescription: JSX.Element | string;
  dockPosition: number;
  slug: string;
  bundle?: ConfigBundle;
  actionButton?: ActionButtonProps;
  icon?: string;
  spaces?: ConfigSpace[];
  version?: number;
};

export type OutletContext = {
  config: object | null;
  crumbs: crumbs | null;
  status: object | null;
  title: string | null;
  showNavBar: boolean;
  tabs: object | string;
  description: string | null;
  setTitle: React.Dispatch<React.SetStateAction<string | undefined>>;
  setShowNavBar: React.Dispatch<React.SetStateAction<boolean>>;
  setDefaultOutletStyle: React.Dispatch<React.SetStateAction<boolean>>;
  setCrumbs: React.Dispatch<React.SetStateAction<crumbs>>;
  setDescription: React.Dispatch<React.SetStateAction<string>>;
  actionButton: ActionButtonProps | null;
  setActionButton: React.Dispatch<
    React.SetStateAction<ActionButtonProps | null>
  >;
  actionSection: JSX.Element | undefined;
  setActionSection: React.Dispatch<
    React.SetStateAction<JSX.Element | undefined>
  >;
};

type PreferredLayoutProps = React.PropsWithChildren & {
  config: Config;
  branch?: string;
};

export default function PreferredLayout({
  config,
  branch,
  children,
}: PreferredLayoutProps) {
  const [crumbs, setCrumbs] = React.useState<crumbs>([
    { name: "Home", href: "/" },
    { name: config.crumbName, href: config.slug },
  ]);
  const [status, setStatus] = React.useState("Status Indicator");
  const [title, setTitle] = React.useState<string | undefined>(config.appName);
  const [actionButton, setActionButton] =
    React.useState<ActionButtonProps | null>(config?.actionButton || null);
  const [actionSection, setActionSection] = React.useState<
    JSX.Element | undefined
  >(undefined);
  const [description, setDescription] = React.useState(config.shortDescription);
  const [icon, setIcon] = React.useState(config?.icon || "PAGE");
  const [showNavBar, setShowNavBar] = React.useState(false);
  //const [tabs, setTabs] = React.useState(config.bundle.leaves);
  const [defaultOutletStyle, setDefaultOutletStyle] = React.useState(true);
  const canvasRef = useRef(null);

  return (
    <>
      <AppMenu
        title={config.crumbName}
        slug={config.slug}
        spaces={config.spaces}
      />
      <div
        id="canvas"
        className="tw-relative tw-overflow-scroll tw-overflow-x-hidden"
      >
        {config.spaces && config.spaces.length > 0 ? (
          <SpacesHeaders
            crumbs={crumbs}
            title={title}
            description={description}
            actionButton={actionButton}
            spaces={config.spaces}
            actionSection={actionSection}
          />
        ) : (
          <Box display={{ base: "none", md: "block" }} bg={"white"} p={"400"}>
            <Header
              crumbs={crumbs}
              title={title}
              description={description}
              actionButton={actionButton}
              icon={icon}
            />
          </Box>
        )}
        <LegacyPageDivider />
        {/*{showNavBar && <NavBar tabs={tabs} />}*/}
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
        >
          {!branch ? (
            <>{children}</>
          ) : (
            <Outlet
              context={{
                setTitle,
                setShowNavBar,
                setDescription,
                setStatus,
                setCrumbs,
                setDefaultOutletStyle,
                crumbs,
                actionButton,
                setActionButton,
                setIcon,
                icon,
                actionSection,
                setActionSection,
              }}
            />
          )}
        </motion.div>
      </div>
    </>
  );
}

export function useAppContext() {
  return useOutletContext<OutletContext>();
}
