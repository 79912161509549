import { ButtonTagVariant, LegacyTag } from "@ttc3k/ttc-design-system";

export const EnvLabel = () => {
  const label =
    import.meta.env.VITE_ENVIRONMENT === "development" ? "development" : null;

  if (!label) return null;

  return <LegacyTag variant={ButtonTagVariant.WarningBold}>Dev</LegacyTag>;
};
