import { useFormHandler } from "core/hooks/useFormHandler";
import {
  EnumPriceTierType,
  EnumProductPublishingStatus,
  EnumProductType,
  ProductManyDocument,
  ProductManyQuery,
  useBookerCreateMutation,
  useProductCreateMutation,
  useProductUpdateMutation,
} from "gql/generated";
import { useCallback, useMemo } from "react";
import { z } from "zod";
import { removeTypename } from "core/utils/removeTypename";

const createEditFeeFormSchema = z.object({
  name: z.string().min(1, "Please enter a name"),
  description: z.string(),
  price: z
    .string()
    .min(1, "Please enter a price")
    .transform(val => parseFloat(val))
    .refine(val => !isNaN(val) && val >= 0, {
      message: "Please enter a positive number",
    })
    .transform(val => val.toString()),
});

export type CreateEditFeeFormValues = z.infer<typeof createEditFeeFormSchema>;

interface CreateEditFeeFormOptions {
  onSuccess?: (
    v: (CreateEditFeeFormValues | undefined) & {
      bookerID: string;
      productID: string;
    },
  ) => void;
  onError?: (message: string) => void;
  isUpsell?: boolean;
  operatorID: string;
  product?: ProductManyQuery["productMany"][0];
}

function useGetDefaultValues(
  product?: ProductManyQuery["productMany"][0],
): CreateEditFeeFormValues {
  return useMemo(
    () => ({
      name: product && product.name.en ? product.name.en : "",
      description:
        product && product.description.en ? product.description.en : "",
      price:
        product && product.pricing.tiers[0]?.price
          ? String(product.pricing.tiers[0]?.price / 100)
          : "0",
    }),
    [product],
  );
}

export const useCreateEditFeeForm = ({
  onSuccess,
  onError,
  operatorID,
  isUpsell = false,
  product,
}: CreateEditFeeFormOptions) => {
  const defaultValues = useGetDefaultValues(product);
  const [createProduct] = useProductCreateMutation();
  const [createBooker] = useBookerCreateMutation();
  const [updateProduct] = useProductUpdateMutation();

  return useFormHandler<CreateEditFeeFormValues>(
    useCallback(
      async data => {
        const payload = { ...data };
        const { price, name, description } = payload;

        const formErrorMessage =
          "Could not create this product, please try again later";

        const emptyMultilingual = { en: "", es: "", fr: "" };

        if (name && !product) {
          const { data: res } = await createProduct({
            variables: {
              inventoryCount: 1,
              record: {
                name: { ...emptyMultilingual, en: name },
                description: { ...emptyMultilingual, en: description },
                shortDescription: emptyMultilingual,
                pricing: {
                  description: emptyMultilingual,
                  tiers: [
                    {
                      label: { ...emptyMultilingual, en: "Fee" },
                      price: price ? Number(price) * 100 : 0,
                      type: EnumPriceTierType.General,
                    },
                  ],
                },
                images: [],
                type: EnumProductType.Fee,
                isUpsellOnly: isUpsell,
                operator: operatorID,
                publishingStatus: EnumProductPublishingStatus.Published,
              },
            },
            refetchQueries: [ProductManyDocument],
          });
          if (!isUpsell) {
            if (res?.productCreate && res?.productCreate.recordId.length > 0) {
              const { data: bookerRes } = await createBooker({
                variables: {
                  record: {
                    operator: operatorID,
                    productBundles: [{ product: res?.productCreate?.recordId }],
                  },
                },
              });

              if (bookerRes && bookerRes.bookerCreate?.recordId) {
                onSuccess?.({
                  ...(data as CreateEditFeeFormValues),
                  bookerID: bookerRes.bookerCreate?.recordId,
                  productID: res.productCreate.recordId,
                });
              }
            } else {
              onError?.(formErrorMessage);
            }
          } else {
            if (res?.productCreate && res?.productCreate.recordId.length > 0) {
              onSuccess?.({
                ...(data as CreateEditFeeFormValues),
                bookerID: "",
                productID: res.productCreate.recordId,
              });
            } else {
              onError?.(formErrorMessage);
            }
          }
        }

        if (product) {
          const cleanedProduct = removeTypename(product);
          const { data: res } = await updateProduct({
            variables: {
              id: cleanedProduct._id,
              record: {
                name: {
                  ...cleanedProduct.name,
                  en: name,
                },
                pricing: {
                  ...cleanedProduct.pricing,
                  tiers: [
                    {
                      label: { ...emptyMultilingual, en: "Fee" },
                      price: price ? Number(price) * 100 : 0,
                      type: EnumPriceTierType.General,
                    },
                  ],
                },
              },
            },
            refetchQueries: [ProductManyDocument],
          });
          if (res?.productUpdate?.recordId) {
            onSuccess?.({
              ...(data as CreateEditFeeFormValues),
              bookerID: "",
              productID: res.productUpdate.recordId,
            });
          }
        }
      },
      [
        createBooker,
        createProduct,
        isUpsell,
        onError,
        onSuccess,
        operatorID,
        product,
        updateProduct,
      ],
    ),
    defaultValues,
    { schema: createEditFeeFormSchema },
  );
};
