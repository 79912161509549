import { gql, useMutation, useQuery } from "@apollo/client";
import { ButtonTagVariant } from "@ttc3k/ttc-design-system";
import { GetOperatorsDocument, Tag } from "../../../../../../gql/generated";

interface Image {
  full_url: string;
  thumbnail_url: string;
  thumbnail_url_x2: string;
  teaser_url: string;
  teaser_url_x2: string;
}
export interface GetOperator {
  getOperator: {
    _id: string;
    operator_name: string;
    website: string;
    phone: string;
    email: string;
    line_1: string;
    line_2: string;
    city: string;
    province: string;
    postal_code: number;
    address: string;
    latitude: number;
    longitude: number;
    short_description: string;
    facebook_link: string;
    instagram_link: string;
    youtube_link: string;
    pinterest_link: string;
    google_maps_link: string;
    isAdmin: boolean;
    images: [Image];
    updated_at: number;
  };
}

export const GET_OPERATOR = gql`
  query getOperator($id: ID!) {
    getOperator(_id: $id) {
      _id
      operator_name
      website
      phone
      email
      line_1
      line_2
      city
      province
      postal_code
      address
      latitude
      longitude
      description {
        en
        fr
      }
      short_description {
        en
        fr
      }
      facebook_link
      instagram_link
      youtube_link
      pinterest_link
      google_mybusiness_link
      tiktok_link
      tripadvisor_link
      status
      note {
        text
        by
        date
        is_public
      }
      hours_of_operation_week {
        sun {
          from
          to
          isActive
        }
        mon {
          from
          to
          isActive
        }
        tue {
          from
          to
          isActive
        }
        wed {
          from
          to
          isActive
        }
        thu {
          from
          to
          isActive
        }
        fri {
          from
          to
          isActive
        }
        sat {
          from
          to
          isActive
        }
      }
      up_to_date_source
      open_hours_option
      checklist {
        verifyAllLinks
        tagYourBusiness
        verifyLocation
        verifyOpeningHours
        uploadNewHighResPhotos
        isYourBusinessDescriptionAccurateAndCompelling
      }
      publishingOptions
      markAsNeedsAttention
      tags {
        type_of_operator
        capacity
        region
        pricing
        amenities
        vtc_custom
        accreditations
      }
      updated_at
      images {
        _id
        full_url
        thumbnail_url
        thumbnail_url_x2
        teaser_url
        teaser_url_x2
        blurhash
        caption {
          en
          fr
        }
      }
      sta_license_status
      seasonality {
        high {
          jan
          feb
          mar
          apr
          may
          jun
          jul
          aug
          sep
          oct
          nov
          dec
        }
        low {
          jan
          feb
          mar
          apr
          may
          jun
          jul
          aug
          sep
          oct
          nov
          dec
        }
        closed {
          jan
          feb
          mar
          apr
          may
          jun
          jul
          aug
          sep
          oct
          nov
          dec
        }
      }
    }
  }
`;

export interface Multilingual {
  en: string;
  fr: string;
  es: string;
}

export type AdjustedTags = {
  accreditation?: Tag[];
  amenities?: Tag[];
  capacity?: Tag[];
  pricing?: Tag[];
  region?: Tag[];
  type_of_operator?: Tag[];
  custom_vtc?: Tag[];
};

const GET_TAGS = bundles => gql`
  query tags {
    ${bundles.map(
      bundle => `
        ${bundle.type.toLowerCase()}:tags(bundle: "${bundle.type}",
          dbName: "${bundle.dbName}",
          collectionName: "${bundle.collectionName}") {
          _id
          bundle
          label {
            en
          }
         }
       `,
    )}
  }
`;

export const useGetOperator = (id: string | undefined) => {
  return useQuery<GetOperator>(GET_OPERATOR, {
    variables: { id: id },
    skip: !id,
    errorPolicy: "ignore",
  });
};

export const useGetTags = bundles => {
  return useQuery<AdjustedTags>(GET_TAGS(bundles));
};

const UPDATE_OPERATOR = gql`
  mutation createOperator($id: ID, $record: UpdateOperatorInput) {
    updateOperator(_id: $id, record: $record) {
      _id
    }
  }
`;

export const useUpdateOperator = (id: string | undefined) => {
  return useMutation(UPDATE_OPERATOR, {
    awaitRefetchQueries: true,
    refetchQueries: [
      { query: GET_OPERATOR, variables: { id: id } },
      GetOperatorsDocument,
    ],
  });
};

export const getTagColorByNumber = (count: number, total: number) => {
  const relation = count / total;
  if (relation === 0) return ButtonTagVariant.Unstyled;
  if (relation === 1) return ButtonTagVariant.SuccessBold;
  return ButtonTagVariant.InfoSubdued;
};
