import { Button, Card } from "@ttc3k/trekker";
import _ from "lodash";
import { EditPencil } from "iconoir-react";
import { UpdateAccomPriceFormValues } from "./useUpdateAccomPriceForm";

type GuestDataCardProps = {
  guest: UpdateAccomPriceFormValues["guests"][0];
  onEditClick: () => void;
};

export const GuestDataCard = ({ guest, onEditClick }: GuestDataCardProps) => {
  return (
    <Card.Surface bg={"white"} boxShadow={"sm"}>
      <Card.Body>
        <Card.BodyContent alignItems={"center"}>
          <Card.Title
            mainText={guest.label}
            subText={_.capitalize(guest.type)}
          />

          <Button
            Icon={EditPencil}
            visual={"outline"}
            onClick={onEditClick}
            type={"button"}
          />
        </Card.BodyContent>
      </Card.Body>
    </Card.Surface>
  );
};
