import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  ProductByIdQuery,
  useBookerByMainProductIdQuery,
} from "gql/generated.ts";
import { ApolloError } from "@apollo/client";
import { Spinner, Text } from "@ttc3k/trekker";
import { useAppContext } from "core/components/apps/PreferredLayout";
import _ from "lodash";
import { BookerLeafNavLayout } from "core/components/shared/BookerLeafNavLayout";
import { isStringProductType } from "../utils/isStringProductType";
import {
  AccommodationDetailsLeaf,
  AccommodationFeaturesLeaf,
  AccommodationPricingLeaf,
  AccommodationUpsellsLeaf,
} from "./leaves/accommodation";
import { BookerPreviewBox } from "./components";

enum AccommodationProductLeaf {
  ProductInfo = "PRODUCT_INFO",
  KeyFeatures = "KEY_FEATURES",
  Photos = "PHOTOS",
  PricingAndGuests = "PRICING_AND_GUESTS",
  Upsells = "UPSELLS",
}

type AccommodationProductProps = {
  product: ProductByIdQuery["productById"];
  loading: boolean;
  error: ApolloError | undefined;
};

export function AccommodationProduct({
  error,
  loading,
  product,
}: AccommodationProductProps) {
  const { setDescription, setTitle } = useAppContext();
  const { id, spaceId } = useParams();

  const { data: bookerData } = useBookerByMainProductIdQuery({
    variables: { productId: id ?? "", operatorId: spaceId },
  });

  const isProductCreated = !!product;
  const accomNavData = [
    { label: "Product Info", value: AccommodationProductLeaf.ProductInfo },
    {
      label: "Key Features",
      value: AccommodationProductLeaf.KeyFeatures,
      disabled: !isProductCreated,
    },
    {
      label: "Photos",
      value: AccommodationProductLeaf.Photos,
      disabled: !isProductCreated,
    },
    {
      label: "Pricing & Guests",
      value: AccommodationProductLeaf.PricingAndGuests,
      disabled: !isProductCreated,
    },
    {
      label: "Upsells",
      value: AccommodationProductLeaf.Upsells,
      disabled: !isProductCreated,
    },
  ];
  const isImageAdded = !!product && product.images.length > 0;
  const isRoomsAdded =
    product &&
    product.accommodationMetadata &&
    product?.accommodationMetadata?.rooms.length > 0;
  const isPricingAdded = product && product.pricing.tiers.length > 0;
  const productBundle =
    bookerData &&
    bookerData.bookerByMainProductId &&
    bookerData.bookerByMainProductId.productBundles.find(
      v => v?.product === product?._id,
    );
  const isUpsellsAdded =
    productBundle && productBundle.addOns && productBundle.addOns.length > 0;
  const completedNavValues = _.compact([
    isProductCreated && AccommodationProductLeaf.ProductInfo,
    isImageAdded && AccommodationProductLeaf.Photos,
    isRoomsAdded && AccommodationProductLeaf.KeyFeatures,
    isPricingAdded && AccommodationProductLeaf.PricingAndGuests,
    isUpsellsAdded && AccommodationProductLeaf.Upsells,
  ]);
  const isParamIDProductType = isStringProductType(id ?? "");

  const [leaf, setLeaf] = useState<AccommodationProductLeaf>(
    AccommodationProductLeaf.ProductInfo,
  );
  useEffect(() => {
    if (product) {
      setTitle(product.name.en ?? "Edit Accommodation");
      setDescription(product.description.en ?? "");
    } else {
      setTitle("Create Accommodation");
      setDescription("");
    }
  }, [product, setDescription, setTitle]);

  if (loading) {
    return <Spinner width={"50px"} height={"50px"} margin={"60px auto"} />;
  }
  if (error && !isParamIDProductType)
    return (
      <Text
        margin={"60px auto"}
        width={"full"}
        textAlign={"center"}
        color={"text.error.mid"}
      >
        Error : {error.message}
      </Text>
    );
  return (
    <BookerLeafNavLayout
      data={accomNavData}
      completedValues={completedNavValues}
      activeValue={leaf}
      onValueChange={v => setLeaf(v.toString() as AccommodationProductLeaf)}
    >
      {leaf === AccommodationProductLeaf.ProductInfo && (
        <AccommodationDetailsLeaf
          isUpdating={isProductCreated}
          productID={product?._id ?? ""}
        />
      )}
      {leaf === AccommodationProductLeaf.KeyFeatures && (
        <AccommodationFeaturesLeaf productID={product?._id ?? ""} />
      )}
      {leaf === AccommodationProductLeaf.Photos && <div>Photos</div>}
      {leaf === AccommodationProductLeaf.PricingAndGuests && (
        <AccommodationPricingLeaf productID={product?._id ?? ""} />
      )}
      {leaf === AccommodationProductLeaf.Upsells && (
        <AccommodationUpsellsLeaf
          productID={product?._id ?? ""}
          operatorID={spaceId ?? product?.operator ?? ""}
          booker={bookerData?.bookerByMainProductId}
        />
      )}

      <BookerPreviewBox
        bookerID={bookerData?.bookerByMainProductId?._id ?? ""}
      />
    </BookerLeafNavLayout>
  );
}
