import { useFormHandler } from "core/hooks/useFormHandler";
import { useCallback } from "react";
import { z } from "zod";

export const guestSchema = z
  .object({
    label: z.string().min(1, "Please add a label"),
    type: z.string().min(1, "Please select a type"),
    minimum: z
      .string()
      .refine(
        val => val === "" || (!isNaN(parseFloat(val)) && parseFloat(val) >= 0),
        {
          message: "Please enter a positive number",
        },
      )
      .transform(val => (val === "" ? "" : parseFloat(val).toString())),
    maximum: z
      .string()
      .refine(
        val => val === "" || (!isNaN(parseFloat(val)) && parseFloat(val) >= 0),
        {
          message: "Please enter a positive number",
        },
      )
      .transform(val => (val === "" ? "" : parseFloat(val).toString())),
    _id: z.string(),
  })
  .superRefine((data, ctx) => {
    const max = data.maximum ? Number(data.maximum) : 0;
    const min = data.minimum ? Number(data.minimum) : 0;
    if (max > 0 && min > 0 && max < min) {
      ctx.addIssue({
        message: "Maximum guests must be greater than the minimum guests",
        path: ["maximum"],
        code: "custom",
      });
    }
  });

export type AddEditGuestDataFormValues = z.infer<typeof guestSchema>;

interface AddEditGuestDataFormOptions {
  onSuccess?: (v: AddEditGuestDataFormValues) => void;
  defaultValues: AddEditGuestDataFormValues;
}

export const useAddEditGuestDataForm = ({
  onSuccess,
  defaultValues,
}: AddEditGuestDataFormOptions) => {
  return useFormHandler<AddEditGuestDataFormValues>(
    useCallback(
      async data => {
        onSuccess?.(data as AddEditGuestDataFormValues);
      },
      [onSuccess],
    ),
    defaultValues,
    { schema: guestSchema },
  );
};
