import { Button, toastFactory } from "@ttc3k/trekker";
import { FormProvider } from "react-hook-form";
import { useAppContext } from "core/components/apps/PreferredLayout";
import { Flex } from "styled-system/jsx";
import { useEffect } from "react";
import { ProductMenuButton } from "../../../components";
import { useUpdateAccomDetailsForm } from "./useUpdateAccomDetailsForm";
import { AccomDetailsFormFields } from "./AccomDetailsFormFields";

type UpdateAccomDetailsFormProps = {
  productID: string;
};

export const UpdateAccomDetailsForm = ({
  productID,
}: UpdateAccomDetailsFormProps) => {
  const { setActionSection } = useAppContext();

  const { form, onSubmit } = useUpdateAccomDetailsForm({
    productID,
    onSuccess: value => {
      toastFactory.create({
        title: "Success!",
        description: `${value?.name} was updated successfully`,
      });
    },
    onError: errorMsg => {
      toastFactory.create({
        title: "Error",
        description: errorMsg,
      });
    },
  });

  const formID = "update-accommodation-details-form";

  useEffect(() => {
    setActionSection(
      <Flex gap={"150"}>
        <ProductMenuButton productID={productID} />
        <Button size={"lg"} type={"submit"} form={formID}>
          Save
        </Button>
      </Flex>,
    );
    return () => setActionSection(undefined);
  }, [setActionSection, productID]);

  return (
    <FormProvider {...form}>
      <form onSubmit={onSubmit} id={formID}>
        <AccomDetailsFormFields isUpdating />
      </form>
    </FormProvider>
  );
};
