import { useAppContext } from "core/components/apps/PreferredLayout";
import { useEffect } from "react";
import { Button, toastFactory } from "@ttc3k/trekker";
import { Flex } from "styled-system/jsx";
import { FormProvider } from "react-hook-form";
import { ProductMenuButton } from "../../components";
import { useUpdateFeeForm } from "./useUpdateFeeForm";
import { FeeDetailsFormFields } from "./FeeDetailsFormFields";

type UpdateFeeFormProps = {
  productID: string;
};

export const UpdateFeeForm = ({ productID }: UpdateFeeFormProps) => {
  const { setActionSection } = useAppContext();

  const { form, onSubmit } = useUpdateFeeForm({
    productID,
    onSuccess: value => {
      toastFactory.create({
        title: "Success!",
        description: `${value?.name} was updated successfully`,
      });
    },
    onError: errorMsg => {
      toastFactory.create({
        title: "Error",
        description: errorMsg,
      });
    },
  });

  const formID = "update-fee-form";

  useEffect(() => {
    setActionSection(
      <Flex gap={"150"}>
        <ProductMenuButton productID={productID} />
        <Button size={"lg"} type={"submit"} form={formID}>
          Save
        </Button>
      </Flex>,
    );

    return () => setActionSection(undefined);
  }, [productID, setActionSection]);

  return (
    <FormProvider {...form}>
      <form id={formID} onSubmit={onSubmit}>
        <FeeDetailsFormFields />
      </form>
    </FormProvider>
  );
};
