import { Spinner, Text } from "@ttc3k/trekker";
import { useAppContext } from "core/components/apps/PreferredLayout.tsx";
import { ProductByIdQuery, useBookerByMainProductIdQuery } from "gql/generated";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BookerLeafNavLayout } from "core/components/shared/BookerLeafNavLayout";
import _ from "lodash";
import { ApolloError } from "@apollo/client";
import { isStringProductType } from "../utils";
import { BookerPreviewBox } from "./components";
import { FeeDetailsLeaf } from "./leaves/fee";

enum FeeProductLeaf {
  ProductInfo = "PRODUCT_INFO",
  Photos = "PHOTOS",
}

type FeeProductProps = {
  product: ProductByIdQuery["productById"];
  loading: boolean;
  error: ApolloError | undefined;
};

export function FeeProduct({ product, loading, error }: FeeProductProps) {
  const { setDescription, setTitle } = useAppContext();
  const { id, spaceId } = useParams();

  const { data: bookerData } = useBookerByMainProductIdQuery({
    variables: { productId: id ?? "", operatorId: spaceId },
  });

  const isProductCreated = !!product;
  const isImageAdded = !!product && product.images.length > 0;
  const feeNavData = [
    { label: "Product Info", value: FeeProductLeaf.ProductInfo },
    {
      label: "Photos",
      value: FeeProductLeaf.Photos,
      disabled: !isProductCreated,
    },
  ];
  const completedNavValues = _.compact([
    isProductCreated && FeeProductLeaf.ProductInfo,
    isImageAdded && FeeProductLeaf.Photos,
  ]);
  const isParamIDProductType = isStringProductType(id ?? "");

  const [leaf, setLeaf] = useState<FeeProductLeaf>(FeeProductLeaf.ProductInfo);

  useEffect(() => {
    if (product) {
      setTitle(product.name.en ?? "Edit Fee Product");
      setDescription(product.description.en ?? "");
    } else {
      setTitle("Create Fee Product");
      setDescription("");
    }
  }, [product, setDescription, setTitle]);

  if (loading) {
    return <Spinner width={"50px"} height={"50px"} margin={"60px auto"} />;
  }
  if (error && !isParamIDProductType)
    return (
      <Text
        margin={"60px auto"}
        width={"full"}
        textAlign={"center"}
        color={"text.error.mid"}
      >
        Error : {error.message}
      </Text>
    );

  return (
    <BookerLeafNavLayout
      data={feeNavData}
      completedValues={completedNavValues}
      activeValue={leaf}
      onValueChange={v => setLeaf(v.toString() as FeeProductLeaf)}
    >
      {leaf === FeeProductLeaf.ProductInfo && (
        <FeeDetailsLeaf
          isUpdating={isProductCreated}
          productID={product?._id ?? ""}
        />
      )}
      {leaf === FeeProductLeaf.Photos && <div>Photos</div>}

      <BookerPreviewBox
        bookerID={bookerData?.bookerByMainProductId?._id ?? ""}
      />
    </BookerLeafNavLayout>
  );
}
