//@ts-nocheck
import { motion } from "framer-motion";
import {
  LegacyButton,
  LegacyLoadingSpinner,
  LegacyProductTile,
  Sizes,
  tokens,
  LegacyTypography,
  TypographyVariant,
  ButtonTagVariant,
} from "@ttc3k/ttc-design-system";
import { useNavigate } from "react-router-dom";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { useCreateNotification } from "../../../../hooks/useCreateNotification.tsx";
import { insertAndAfterLastComma } from "../../../../utils/insertAndAfterLastComma.ts";
import { useOnboardingContext } from "../../Layout.tsx";
import SlideInPage from "../../../animation/SlideInPage.tsx";
import { getLocalV4Ips } from "vite-plugin-mkcert/dist/lib/util";

const CREATE_TENANT = gql`
  mutation createTenant(
    $user_id: String
    $operators: String
    $registeredApps: String
  ) {
    createTenant(
      user_id: $user_id
      operators: $operators
      registeredApps: $registeredApps
    ) {
      _id
    }
  }
`;

const UPDATE_USER_METADATA = gql`
  mutation updateUserMetadata(
    $id: String
    $body: String
    $tenantId: String
    $userObject: String
  ) {
    updateUserMetadata(user_id: $id, body: $body) {
      user_id
    }
    addUserToTenant(tenantId: $tenantId, userObject: $userObject) {
      name
    }
  }
`;

const GET_OPERATORS_ONBOARDING = gql`
  query operators($tenants: String, $limit: Int, $offset: Int, $email: String) {
    getOperators(
      tenants: $tenants
      limit: $limit
      offset: $offset
      email: $email
    ) {
      data {
        operator_name
        address
        _id
      }
    }
  }
`;

export const ClaimFoundBusiness = ({ urlAfterClaimFlow }) => {
  const { user } = useAuth0();
  const navigate = useNavigate();
  const {
    baseURL,
    businesses,
    setBusinesses,
    claimSuccessNextURL,
    saveOnboardingStep,
    successSetOnboardingStatus,
    loadingSetOnboardingStatus,
    errorSetOnboardingStatus,
    customPortalData,
  } = useOnboardingContext();
  const [
    updateUserMetadata,
    { data: dataUser, loading: loadingUser, error: errorUser },
  ] = useMutation(UPDATE_USER_METADATA);
  const { data, loading, error } = useQuery(GET_OPERATORS_ONBOARDING, {
    variables: {
      limit: 30,
      offset: 0,
      email: user?.email,
      tenants: JSON.stringify([
        user?.sub,
        customPortalData.settings.tenants[0],
      ]),
    },
  });

  const [loadingState, setLoadingState] = useState(false);

  const [createNotification, { loading: notificationLoading }] =
    useCreateNotification();
  const [createTenant, { data: saved }] = useMutation(CREATE_TENANT);

  useEffect(() => {
    if (data?.getOperators.data) {
      setBusinesses(data.getOperators.data);
    }
  }, [data]);

  if (loading) {
    return <h1>Loading .... </h1>;
  }

  if (error) {
    return <h1>Error .... </h1>;
  }

  const handleApprove = operators => {
    setLoadingState(true);
    createTenant({
      variables: {
        user_id: user?.sub,
        operators: JSON.stringify(operators.map(b => b._id)),
        registeredApps: JSON.stringify(
          customPortalData?.settings?.registeredApps,
        ),
      },
    })
      .then(r =>
        updateUserMetadata({
          variables: {
            id: user.sub,
            body: JSON.stringify({
              app_metadata: {
                tenants: [user.sub],
                onboarding: {
                  complete: urlAfterClaimFlow == "SUCCESS",
                  operatorId: operators[0]._id,
                  businesses: operators,
                },
              },
            }),
            tenantId: JSON.stringify([user.sub]),
            userObject: JSON.stringify(user),
          },
        }),
      )
      .then(r =>
        saveOnboardingStep({
          variables: {
            id: user.sub,
            page: `${baseURL}/${urlAfterClaimFlow}`,
            type: "EMAIL_MATCH",
          },
        }),
      )
      .then(r =>
        createNotification({
          variables: {
            notification: JSON.stringify({
              tenant: user?.sub,
              title: `Profile${businesses.length > 1 ? "s" : ""} claimed`,
              button: { label: "Review", path: "/app/directory" },
              message: insertAndAfterLastComma(
                operators
                  .map(business => `${business.operator_name}`)
                  .join(", "),
              ),
            }),
          },
        }),
      )
      .then(result =>
        navigate(`${baseURL}/${urlAfterClaimFlow}`, {
          state: { operatorId: operators[0]._id },
        }),
      );
  };

  const handleOops = () => {
    navigate(`${baseURL}/help`);
  };

  return (
    <SlideInPage>
      {data.getOperators.data.length == 0 ? (
        <LegacyTypography>Something went wrong.</LegacyTypography>
      ) : (
        <div className={"tw-w-full md:tw-w-96 tw-flex tw-flex-col tw-gap-5"}>
          <LegacyTypography className="tw-mt-10 tw-mb-6">
            We found the following businesses associated with{" "}
            <b>{user.email}</b>
          </LegacyTypography>
          {data.getOperators.data.map(business => {
            return (
              <LegacyProductTile
                outlined
                key={business._id}
                style={{
                  padding: `${tokens.spacing.space250} ${tokens.spacing.space300}`,
                  cursor: "default",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <div className={"tw-flex tw-flex-col tw-gap-1 tw-text-left"}>
                  <LegacyTypography variant={TypographyVariant.primaryBold}>
                    {business.operator_name}
                  </LegacyTypography>
                  <LegacyTypography color={tokens.semantic.textSubtle}>
                    {business.address}
                  </LegacyTypography>
                </div>
              </LegacyProductTile>
            );
          })}
        </div>
      )}
      {data.getOperators.data.length == 0 ? (
        <LegacyButton onClick={handleOops} type="button" fullWidth>
          I need assistance...
        </LegacyButton>
      ) : (
        <>
          <LegacyTypography className={"tw-pt-6"}>
            Is this correct?
          </LegacyTypography>
          <div className="tw-w-full tw-flex tw-justify-between tw-gap-3">
            <LegacyButton
              onClick={handleOops}
              variant={ButtonTagVariant.Secondary}
              type="button"
              fullWidth
            >
              No, something's wrong...
            </LegacyButton>
            <LegacyButton
              onClick={() => handleApprove(businesses)}
              type="button"
              fullWidth
              disabled={businesses.length == 0}
            >
              {loadingUser ||
              loadingSetOnboardingStatus ||
              notificationLoading ||
              loadingState ? (
                <LegacyLoadingSpinner size={20} />
              ) : (
                "Continue"
              )}
            </LegacyButton>
          </div>
        </>
      )}
    </SlideInPage>
  );
};
