import { BookerLeafHeader } from "core/components/shared/BookerLeafHeader";
import { ControlledPriceInput } from "core/components/shared/ControlledPriceInput";
import { useEffect, useState } from "react";
import { FormProvider, useController, useWatch } from "react-hook-form";
import { Flex } from "styled-system/jsx";
import { Button, Text, toastFactory } from "@ttc3k/trekker";
import { useAppContext } from "core/components/apps/PreferredLayout";
import { nanoid } from "nanoid";
import { EnumPriceTierType } from "gql/generated";
import { BookerLeafSubheader } from "core/components/shared/BookerLeafSubheader";
import { ControlledInput } from "apps/custom/ttc/booker/shared";
import { ProductMenuButton } from "../../components";
import {
  AddEditGuestTypeModal,
  GuestDataCard,
  UpdateAccomPriceFormValues,
  useUpdateAccomPriceForm,
} from "./components";

type AccommodationPricingLeafProps = {
  productID: string;
};

export const AccommodationPricingLeaf = ({
  productID,
}: AccommodationPricingLeafProps) => {
  const [currentGuestData, setCurrentGuestData] = useState<
    UpdateAccomPriceFormValues["guests"][0] | undefined
  >(undefined);
  const { setActionSection } = useAppContext();
  const { form, onSubmit } = useUpdateAccomPriceForm({
    productID,
    onSuccess: name => {
      toastFactory.create({
        title: "Success!",
        description: `${name} was updated successfully`,
      });
    },
    onError: errorMsg => {
      toastFactory.create({
        title: "Error",
        description: errorMsg,
      });
    },
  });

  const watchedGuests = useWatch({ control: form.control, name: "guests" });
  const { fieldState: guestsFieldState } = useController({
    name: "guests",
    control: form.control,
  });

  const handleGuestSave = (
    guest: UpdateAccomPriceFormValues["guests"][0],
    isDeleting: boolean,
  ) => {
    const isEditingGuest = watchedGuests.find(g => g._id === guest._id);

    const newGuests = isEditingGuest
      ? [...watchedGuests].map(g => {
          if (g._id === guest._id) {
            return { ...guest, _id: g._id };
          }
          return g;
        })
      : [...watchedGuests, guest];

    form.setValue(
      "guests",
      isDeleting ? newGuests.filter(g => g._id !== guest._id) : newGuests,
    );
    setCurrentGuestData(undefined);
  };

  const formID = "update-accommodation-pricing-form";

  useEffect(() => {
    setActionSection(
      <Flex gap={"150"}>
        <ProductMenuButton productID={productID} />
        <Button size={"lg"} type={"submit"} form={formID}>
          Save
        </Button>
      </Flex>,
    );

    return () => setActionSection(undefined);
  }, [productID, setActionSection]);

  return (
    <>
      <FormProvider {...form}>
        <form onSubmit={onSubmit} id={formID}>
          <Flex flexDir={"column"} gap={"400"} width={"full"}>
            <BookerLeafHeader title={"Pricing & Guests"} />
            <Flex flexDir={"column"} gap={"150"} width={"full"}>
              <BookerLeafSubheader
                title={"Nightly rate"}
                description={
                  "Accommodation products are priced per night. Please set your nightly rate below."
                }
              />
              <ControlledPriceInput name={"price"} label={"Nightly rate"} />
            </Flex>

            <Flex flexDir={"column"} gap={"150"} width={"full"}>
              <BookerLeafSubheader
                title={"Guest Rules"}
                description={
                  "Specify the minimum and maximum number of guests that can stay in this accommodation."
                }
              />
              <Flex gap={"150"}>
                <ControlledInput
                  label={"Minimum"}
                  name={"minimum"}
                  type={"number"}
                />
                <ControlledInput
                  label={"Maximum"}
                  name={"maximum"}
                  type={"number"}
                />
              </Flex>
            </Flex>

            <Flex flexDir={"column"} gap={"300"} width={"full"}>
              <BookerLeafSubheader
                title={"Guest Types"}
                description={
                  "If you want to capture different guest types (adults, children, seniors, etc.), please add them below. We’ve created a default “Adult” type below to get you started, but feel free to edit it!"
                }
              />
              {guestsFieldState.error && (
                <Text color={"text.error.mid"} visual={"smallMedium"}>
                  {guestsFieldState.error.message}
                </Text>
              )}
              {watchedGuests.map(g => {
                return (
                  <GuestDataCard
                    guest={g}
                    key={g._id}
                    onEditClick={() => setCurrentGuestData(g)}
                  />
                );
              })}
              <Button
                visual={"outline"}
                size={"lg"}
                type={"button"}
                onClick={() =>
                  setCurrentGuestData({
                    _id: nanoid(),
                    label: "",
                    type: EnumPriceTierType.Adult,
                    minimum: "",
                    maximum: "",
                  })
                }
                width={"max-content"}
              >
                Add a guest type
              </Button>
            </Flex>
          </Flex>
        </form>
      </FormProvider>
      <AddEditGuestTypeModal
        isOpen={currentGuestData !== undefined}
        onClose={() => setCurrentGuestData(undefined)}
        guest={
          currentGuestData ?? {
            _id: nanoid(),
            label: "",
            type: EnumPriceTierType.Adult,
            minimum: "",
            maximum: "",
          }
        }
        onSave={handleGuestSave}
      />
    </>
  );
};
