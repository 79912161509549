export const operatorStatus = [
  { value: "Updated", label: "Updated" },
  { value: "Approved", label: "Approved" },
  {
    value: "Needs Attention",
    label: "Needs Attention",
  },
  {
    value: "Claimed",
    label: "Claimed",
  },
];

export const tagBundles = [
  { type: "REGION", dbName: "Core", collectionName: "tags" },
  { type: "PRICING", dbName: "Core", collectionName: "tags" },
  { type: "TYPE_OF_OPERATOR", dbName: "Core", collectionName: "tags" },
  { type: "AMENITIES", dbName: "Core", collectionName: "tags" },
  {
    type: "CUSTOM_VTC",
    dbName: "app_listings",
    collectionName: "client_vtc_tags",
  },
  {
    type: "CUSTOM_OHTO",
    dbName: "app_listings",
    collectionName: "client_ohto_tags",
  },
  { type: "ACCREDITATION", dbName: "Core", collectionName: "tags" },
];

export const tempOHTOtagBundles = [
  { type: "REGION", dbName: "Core", collectionName: "tagsohto" },
  { type: "PRICING", dbName: "Core", collectionName: "tagsohto" },
  { type: "TYPE_OF_OPERATOR", dbName: "Core", collectionName: "tagsohto" },
  { type: "AMENITIES", dbName: "Core", collectionName: "tagsohto" },
  {
    type: "CUSTOM_OHTO",
    dbName: "app_listings",
    collectionName: "client_ohto_tags",
  },
  { type: "ACCREDITATION", dbName: "Core", collectionName: "tagsohto" },
];

export const tempNEONTtagBundles = [
  { type: "REGION", dbName: "Core", collectionName: "tagsneont" },
  { type: "PRICING", dbName: "Core", collectionName: "tagsneont" },
  { type: "TYPE_OF_OPERATOR", dbName: "Core", collectionName: "tagsneont" },
  { type: "AMENITIES", dbName: "Core", collectionName: "tagsneont" },
  {
    type: "CUSTOM_NEONT",
    dbName: "app_listings",
    collectionName: "client_neont_tags",
  },
  { type: "ACCREDITATION", dbName: "Core", collectionName: "tagsneont" },
];
